import './SearchHeader.css';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useAuth } from '../../../auth/auth';
import SignInModal from '../../../auth/SignInModal/SignInModal'; 
import { useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { generateClient } from 'aws-amplify/api';
import * as queries from '../../../graphQL/queries';
import MessagesModal from "../../Syn/Messages/MessagesModal";
import FilterModal from './FilterModal/FilterModal';
import contractorTypes from '../../Syn/ContractorTypes';

const SearchHeader = ({ onRadiusChange, onSpecialtiesChange, onPriceRangeChange, onRatingsChange, onResetFilters, highestPrice }) => {
  const { type, zip, radius: urlRadius } = useParams();
  const [selectedContractorType, setSelectedContractorType] = useState(null);
  const [location, setLocation] = useState('');
  const [selectedRadius, setSelectedRadius] = useState({ label: '10 miles', value: 10 });
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState([0, highestPrice]);
  const [selectedRatings, setSelectedRatings] = useState({ overall: [0, 5], quality: [0, 5], cost: [0, 5], timeliness: [0, 5] });
  const [initialRange, setInitialRange] = useState([0, highestPrice]); 
  const navigate = useNavigate();
  const { user, userType, SignOut, userID } = useAuth();
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [hasUnreadInvoices, setHasUnreadInvoices] = useState(false);
  const [hasPendingRequests, setHasPendingRequests] = useState(false);
  const client = generateClient();
  const [isDetailedView, setIsDetailedView] = useState(false);
  const searchBarRef = useRef(null);
  const [profilePictureKey, setProfilePictureKey] = useState('');
  const [errors, setErrors] = useState({ contractorType: false, location: false });
  const API_URL = process.env.REACT_APP_PROXY_SERVER_API_URL;
  const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL;

  useEffect(() => {
    if (user) {
      fetchProfilePicture();
      fetchUnreadMessages();
      fetchUnreadInvoices();
      fetchPendingRequests();
      setShowSignInModal(false);
    }
  }, [user]);

  const fetchProfilePicture = async () => {
    try {
      let profileKey = '';
      if (userType === 'Company') {
        const { data } = await client.graphql({
          query: queries.getCompany,
          variables: { id: userID },
        });
        profileKey = data.getCompany.profilepicturekey || '';
      } else {
        const { data } = await client.graphql({
          query: queries.getUser,
          variables: { id: userID },
        });
        profileKey = data.getUser.profilepicturekey || '';
      }
      setProfilePictureKey(profileKey);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  };

  const fetchUnreadMessages = async () => {
    try {
      const result = await client.graphql({
        query: queries.listMessages,
        variables: { filter: { receiverID: { eq: userID }, readStatus: { eq: false } } },
      });
      const unreadMessages = result.data.listMessages.items;
      setHasUnreadMessages(unreadMessages.length > 0);
    } catch (error) {
      console.error('Error fetching unread messages:', error);
    }
  };

  const fetchUnreadInvoices = async () => {
    try {
      const result = await client.graphql({
        query: queries.invoicesByUserID,
        variables: { userID: userID },
      });
      const unreadInvoices = result.data.invoicesByUserID.items.some(invoice => !invoice.readStatus);
      setHasUnreadInvoices(unreadInvoices);
    } catch (error) {
      console.error('Error fetching unread invoices:', error);
    }
  };

  const fetchPendingRequests = async () => {
    try {
      const result = await client.graphql({
        query: queries.requestsByCompanyID,
        variables: { companyID: userID, filter: { isPending: { eq: true } } },
      });
      const pendingRequests = result.data.requestsByCompanyID.items;
      setHasPendingRequests(pendingRequests.length > 0);
    } catch (error) {
      console.error('Error fetching pending requests:', error);
    }
  };

  const createDefaultProfileImage = (username) => {
    const firstChar = username ? username.charAt(0).toUpperCase() : '';
    return `data:image/svg+xml;base64,${btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
      <circle cx="75" cy="75" r="75" fill="#23313d"/>
      <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
    </svg>`)}`;
  };

  const toggleSignInModal = () => {
    setShowSignInModal(!showSignInModal);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const goToHome = () => {
    navigate('/');
  };

  const handleLogout = async () => {
    await SignOut();
    navigate('/');
    setShowDropdown(false);
  };

  const handleGoToProfile = () => {
    const profilePath = userType === 'Company'
      ? `/contractor/${user.companyUser}`
      : `/user/${user.user}`;
    navigate(profilePath);
    setShowDropdown(false);
  };

  const handleGoToInvoices = () => {
    const invoicePath = userType === 'Company'
      ? `/contractor/${user.companyUser}/invoice-dash`
      : `/user/${user.user}/invoice-dash`;
    navigate(invoicePath);
    setShowDropdown(false);
  };

  const handleGoToMessages = () => {
    setShowMessagesModal(true);
    setShowDropdown(false);
  };

  const handleCalendarClick = () => {
    navigate(`/contractor/${user.companyUser}/calendar`);
    setShowDropdown(false);
  };

  const toggleMessagesModal = () => {
    setShowMessagesModal(!showMessagesModal);
  };

  const handleToggleView = () => {
    setIsDetailedView(true);
  };

  const profileImageSrc = profilePictureKey
    ? `https://media.spiggl.com/public/${profilePictureKey}`
    : userType === 'Company'
      ? createDefaultProfileImage(user.companyUser)
      : createDefaultProfileImage(user.user);

  const combinedValue = `${selectedContractorType ? selectedContractorType.value : 'Contractor Type'} in ${location ? location : 'Location'}`;
  
  const radiusOptions = useMemo(() => [
    { label: '5 miles', value: 5 },
    { label: '10 miles', value: 10 },
    { label: '15 miles', value: 15 },
    { label: '20 miles', value: 20 },
    { label: '25 miles', value: 25 },
    { label: '30 miles', value: 30 },
    { label: '35 miles', value: 35 },
    { label: '40 miles', value: 40 },
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (type && zip) {
      setSelectedContractorType(contractorTypes.find(option => option.value === type));
      setLocation(zip);
    }
    if (urlRadius) {
      setSelectedRadius(radiusOptions.find(option => option.value === parseInt(urlRadius, 10)) || { label: '20 miles', value: 20 });
    }
  }, [type, zip, urlRadius, contractorTypes, radiusOptions]);

  useEffect(() => {
    const fetchPriceData = async () => {
      const client = generateClient();
      const { data } = await client.graphql({ query: queries.listCompanies });
      const companies = data.listCompanies.items;

      const prices = companies.map(company => ({
        low: parseInt(company.lowRange, 10),
        high: parseInt(company.highRange, 10)
      }));

      const allPrices = prices.flatMap(price => [price.low, price.high]);
      const minPrice = Math.min(...allPrices);
      const maxPrice = Math.max(...allPrices);

      setInitialRange([minPrice, maxPrice]);
      setSelectedPriceRange([minPrice, maxPrice]);
    };

    fetchPriceData();
  }, []);

  const handleSearch = () => {
    if (!selectedContractorType || !location) {
      setErrors({
        contractorType: !selectedContractorType,
        location: !location
      });
      return;
    }
    
    const type = selectedContractorType ? encodeURIComponent(selectedContractorType.value) : '';
    const loc = encodeURIComponent(location);
    navigate(`/search-results/${type}/${loc}`);
    setIsDetailedView(false);
  };

  const handleContractorTypeChange = (selectedOption) => {
    setSelectedContractorType(selectedOption);
    setErrors((prev) => ({ ...prev, contractorType: false }));
  };

  const handleLocationChange = (selectedOption) => {
    setLocation(selectedOption ? selectedOption.value : '');
    setErrors((prev) => ({ ...prev, location: false }));
  };

  const handleRadiusChange = (selectedOption) => {
    setSelectedRadius(selectedOption);
    if (onRadiusChange) {
      onRadiusChange(selectedOption.value); 
    }
  };

  const handleSpecialtiesChange = (selectedSpecialties) => {
    setSelectedSpecialties(selectedSpecialties);
    if (onSpecialtiesChange) {
      onSpecialtiesChange(selectedSpecialties);
    }
  };

  const handlePriceRangeChange = (priceRange) => {
    setSelectedPriceRange(priceRange);
    if (onPriceRangeChange) {
      onPriceRangeChange(priceRange);
    }
  };

  const handleRatingsChange = (ratings) => {
    setSelectedRatings(ratings);
    if (onRatingsChange) {
      onRatingsChange(ratings);
    }
  };

  const handleResetFilters = () => {
    setSelectedRadius({ label: '10 miles', value: 10 });
    setSelectedSpecialties([]);
    setSelectedPriceRange(initialRange); 
    setSelectedRatings({ overall: [0, 5], quality: [0, 5], cost: [0, 5], timeliness: [0, 5] });
    if (onResetFilters) {
      onResetFilters();
    }
  };

  const loadOptions = async (inputValue) => {
    try {
      const response = await fetch(`${API_URL}?input=${encodeURIComponent(inputValue)}`);
      const data = await response.json();
      
      if (response.status !== 200) {
        console.error("Error response:", data);
        throw new Error(data.error || "Error fetching location suggestions");
      }
      
      if (data.status !== "OK") {
        console.error("Google API error:", data);
        throw new Error("Error fetching location suggestions from Google API");
      }
      
      return data.predictions.map(prediction => {
        const mainText = prediction.structured_formatting.main_text;
        const secondaryText = prediction.structured_formatting.secondary_text.split(',')[0];
        return {
          label: `${mainText}, ${secondaryText}`,
          value: `${mainText}, ${secondaryText}`,
        };
      });
    } catch (error) {
      console.error("Error fetching location suggestions: ", error);
      return [];
    }
  };

  const convertCoordsToLocation = async (latitude, longitude) => {
    try {
      const geocodeUrl = `${API_GATEWAY_URL}`;
      console.log("Fetching from URL:", geocodeUrl);
  
      const response = await fetch(geocodeUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ latitude, longitude }),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        console.error("Response error:", {
          status: response.status,
          statusText: response.statusText,
          body: data,
        });
        throw new Error(`Failed to fetch geocoding data: ${response.status} ${JSON.stringify(data)}`);
      }
  
      if (data.results && data.results.length > 0) {
        const locality = data.results[0].address_components.find(
          (component) =>
            component.types.includes("locality") ||
            component.types.includes("sublocality") ||
            component.types.includes("postal_town")
        );
  
        const state = data.results[0].address_components.find(
          (component) => component.types.includes("administrative_area_level_1")
        );
  
        if (locality && state) {
          const locationString = `${locality.long_name}, ${state.short_name}`;
          setLocation(locationString);
          setErrors((prev) => ({ ...prev, location: false }));
        } else {
          const formattedAddress = data.results[0].formatted_address.split(",").slice(0, 2).join(",").trim();
          setLocation(formattedAddress);
          setErrors((prev) => ({ ...prev, location: false }));
        }
      } else {
        throw new Error("No results found in geocoding response");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      setErrors((prev) => ({
        ...prev,
        location: true,
        locationError: "Could not determine your location. Please enter it manually.",
      }));
    }
  };
  
  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          convertCoordsToLocation(latitude, longitude);
        },
        (error) => {
          console.error("Geolocation error:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const toggleFilterModal = (e) => {
    if (e) e.stopPropagation();
    setShowFilterModal(!showFilterModal);
  };

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsDetailedView(false);
    }
  };

  const handleClickOutside = (e) => {
    if (searchBarRef.current && !searchBarRef.current.contains(e.target)) {
      setIsDetailedView(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="Header">
      <div className='searched-header-width'>
        <div className="brand-header" onClick={goToHome}>
          <img src='/favicon.png' alt="Company Logo" className="header-logo" />
          <span className="brand-name">Spiggl</span>
        </div>
        <div className="header-search-bar" onBlur={handleBlur} tabIndex="0" ref={searchBarRef}>
          {isDetailedView ? (
            <div className="header-search-group">
              <Select
                classNamePrefix="header-react-select"
                value={selectedContractorType}
                onChange={(option) => {
                  setSelectedContractorType(option);
                  handleContractorTypeChange(option);
                }}
                options={contractorTypes}
                placeholder="Contractor Type"
                className="react-header-search-type"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderRadius: '10px',
                    height: '20px',
                    backgroundColor: '#f1f0f0',
                    fontFamily: '"Inter-Regular", sans-serif',
                    fontSize: '14px',
                    border: errors.contractorType ? '1px solid #d3211b' : (isDetailedView ? '1px solid #045096' : 'none'),
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                  }),
                  input: (provided) => ({
                    ...provided,
                    display: 'flex',
                    alignItems: 'center',
                  }),
                }}
              />
              <div className="header-location-input" style={{ flex: 1 }}>
                <AsyncSelect
                  classNamePrefix="header-react-select"
                  value={location ? { label: location, value: location } : null}
                  onChange={handleLocationChange}
                  loadOptions={loadOptions}
                  placeholder="Location"
                  className="react-header-search-location"
                  isClearable
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderRadius: '10px',
                      height: '20px',
                      backgroundColor: '#f1f0f0',
                      fontFamily: '"Inter-Regular", sans-serif',
                      fontSize: '14px',
                      border: errors.location ? '1px solid #d3211b' : (isDetailedView ? '1px solid #045096' : 'none'),
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      textAlign: 'left',
                      display: 'flex',
                      alignItems: 'center',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      textAlign: 'left',
                      display: 'flex',
                      alignItems: 'center',
                    }),
                    input: (provided) => ({
                      ...provided,
                      display: 'flex',
                      alignItems: 'center',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
                <img 
                  src="/icons/location.png" 
                  alt="Location Icon" 
                  className="header-location-icon" 
                  onClick={handleLocationClick} 
                  style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', width: '18px', height: '18px' }}
                />
              </div>
              <button className="header-search-button" onClick={handleSearch}>
                Search
              </button>
            </div>
          ) : (
            <div className="header-combined-view" onClick={handleToggleView}>
              <input
                type="text"
                value={combinedValue}
                readOnly
                placeholder="Contractor Type in Location"
                className="header-search-input header-combined-input"
                style={{ backgroundColor: '#f1f0f0', borderRadius: '10px', padding: '0.5rem', height: '20px', minHeight: '20px' }}
              />
              <button onClick={toggleFilterModal} className="header-filter-button">
                <img src="/icons/adjust.png" alt="Adjust Filters" className="header-adjust-icon" />
              </button>
            </div>
          )}
        </div>
        <nav className="header-user-nav">
          {user ? (
            <div className="header-profile-button" onClick={toggleDropdown}>
              <img
                src={profileImageSrc}
                alt="Profile"
                className="header-profile-picture"
              />
              {(hasUnreadMessages || hasUnreadInvoices || hasPendingRequests) && <div className="header-unread-indicator" />}
              {showDropdown && (
                <div className="searched-header-dropdown-menu">
                  <div className="header-dropdown-profile" onClick={handleGoToProfile}>
                    <img
                      src={profileImageSrc}
                      alt="Profile"
                      className="header-dropdown-profile-picture"
                    />
                    <div className="header-dropdown-profile-text">
                      <span>View Profile</span>
                      <span className="dropdown-username">{userType === 'Company' ? user.companyUser : user.user}</span>
                    </div>
                  </div>
                  <ul>
                    <li onClick={handleGoToInvoices}>
                      <img src="/icons/invoice.png" alt="Invoices" />
                      Invoices
                      {hasUnreadInvoices && <div className="invoices-unread-indicator" />}
                    </li>
                    <li onClick={handleGoToMessages}>
                      <img src="/icons/message.png" alt="Messages" />
                      Messages
                      {hasUnreadMessages && <div className="messages-unread-indicator" />}
                    </li>
                    {userType === 'Company' && (
                      <li onClick={handleCalendarClick}>
                        <img src="/icons/schedule.png" alt="Schedule" />
                        Schedule
                        {hasPendingRequests && <div className="schedule-unread-indicator" />}
                      </li>
                    )}
                    <li onClick={handleLogout}>
                      <img src="/icons/logout.png" alt="Logout" />
                      Log Out
                    </li>
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <button className="header-nav-button header-sign-in-button" onClick={toggleSignInModal}>
              Log In
            </button>
          )}
        </nav>
        {showSignInModal && <SignInModal onClose={toggleSignInModal} />}
        {showMessagesModal && <MessagesModal userID={userID} onClose={toggleMessagesModal} />}
        {showFilterModal && <FilterModal 
          selectedRadius={selectedRadius}
          selectedSpecialties={selectedSpecialties}
          selectedPriceRange={selectedPriceRange}
          selectedRatings={selectedRatings}
          handleRadiusChange={handleRadiusChange}
          handleSpecialtiesChange={handleSpecialtiesChange}
          handlePriceRangeChange={handlePriceRangeChange}
          handleRatingsChange={handleRatingsChange}
          onClose={toggleFilterModal}
          highestPrice={highestPrice}
          onApplyFilters={(specialties) => {
            handleSpecialtiesChange(specialties);
            handleSearch();
          }}
          onResetFilters={handleResetFilters}
          initialRange={initialRange} 
        />} 
      </div>
    </header>
  );
};

export default SearchHeader;
