import React, { useState, useEffect } from 'react';
import "./Searched.css";
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import SearchHeader from '../../Headers/SearchHeader/SearchHeader';
import { generateClient } from "aws-amplify/api";
import { Helmet } from 'react-helmet';
import { listCompanies, reviewsByCompanyID, highlightsByCompanyID, specialtiesByCompanyID } from '../../../graphQL/queries';
import ImageModal from "../ImageModal/ImageModal";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SearchResults = () => {
  const navigate = useNavigate();
  const { type, zip } = useParams();
  const client = generateClient();
  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 20;
  const [totalPages, setTotalPages] = useState(1);
  const [selectedRadius, setSelectedRadius] = useState({ label: '10 miles', value: 10 });
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState([0, 0]);
  const [selectedRatings, setSelectedRatings] = useState({ overall: [0, 5], quality: [0, 5], cost: [0, 5], timeliness: [0, 5] });
  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [currentAttachments, setCurrentAttachments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentZipcode, setCurrentZipcode] = useState(zip);
  const [highestPrice, setHighestPrice] = useState(100);
  const openModal = (index, attachments) => {
    setSelectedImageIndex(index);
    setCurrentAttachments(attachments);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getLatLngFromZip = async (zipcode) => {
    try {
      const apiKeyResponse = await fetch(`${process.env.REACT_APP_GOOGLE_API_URL}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });      
  
      if (!apiKeyResponse.ok) {
        console.error("Failed to fetch API key:", apiKeyResponse.statusText);
        return null;
      }
  
      const { apiKey } = await apiKeyResponse.json();
  
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=${apiKey}`;
      const response = await fetch(url);
  
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        return { latitude: location.lat, longitude: location.lng };
      } else {
        console.error("No results found");
        return null;
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      return null;
    }
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanceInKm = R * c;
    const distanceInMiles = distanceInKm * 0.621371;
    return distanceInMiles;
  };

  const fetchSpecialtiesForCompany = async (companyId) => {
    const { data } = await client.graphql({
      query: specialtiesByCompanyID,
      variables: { companyID: companyId }
    });
    return data.specialtiesByCompanyID.items;
  };

  const filterBySpecialties = async (companies) => {
    if (selectedSpecialties.length === 0) return companies;

    const filteredCompanies = [];
    for (const company of companies) {
      const specialties = await fetchSpecialtiesForCompany(company.id);
      const matchedSpecialties = specialties.map(s => s.specialties).flat();
      if (selectedSpecialties.some(s => matchedSpecialties.includes(s))) {
        filteredCompanies.push(company);
      }
    }
    return filteredCompanies;
  };

  const filterByPriceRange = (companies) => {
    if (selectedPriceRange[0] === 0 && selectedPriceRange[1] === 0) return companies;

    return companies.filter(company => {
      const lowRange = parseInt(company.lowRange, 10);
      const highRange = parseInt(company.highRange, 10);
      return (
        (lowRange >= selectedPriceRange[0] && lowRange <= selectedPriceRange[1]) ||
        (highRange >= selectedPriceRange[0] && highRange <= selectedPriceRange[1]) ||
        (lowRange <= selectedPriceRange[0] && highRange >= selectedPriceRange[1])
      );
    });
  };

  const filterByRatings = (companies) => {
    return companies.filter(company => {
      return (
        company.averageOverall >= selectedRatings.overall[0] && company.averageOverall <= selectedRatings.overall[1] &&
        company.averageQuality >= selectedRatings.quality[0] && company.averageQuality <= selectedRatings.quality[1] &&
        company.averageCost >= selectedRatings.cost[0] && company.averageCost <= selectedRatings.cost[1] &&
        company.averageTimeliness >= selectedRatings.timeliness[0] && company.averageTimeliness <= selectedRatings.timeliness[1]
      );
    });
  };

  const searchContractors = async (userZipcode, contractorType, radius) => {
    const userLocation = await getLatLngFromZip(userZipcode);
    if (!userLocation) return [];

    const filter = {
      and: [
        { companyType: { contains: contractorType } },
        { zipcode: { ne: null } }
      ]
    };

    const { data } = await client.graphql({
      query: listCompanies,
      variables: { filter }
    });

    if (!data || !data.listCompanies || !data.listCompanies.items) {
      console.error("No companies found");
      return [];
    }

    const contractors = data.listCompanies.items;

    const filteredContractors = (await Promise.all(contractors.map(async (contractor) => {
      const contractorLocation = await getLatLngFromZip(contractor.zipcode);
      if (!contractorLocation) return null;
      const distance = calculateDistance(userLocation.latitude, userLocation.longitude, contractorLocation.latitude, contractorLocation.longitude);
      return distance <= radius ? contractor : null;
    }))).filter(contractor => contractor !== null);

    return await filterBySpecialties(filteredContractors);
  };

  const fetchCompanyReviews = async (companyID) => {
    const { data } = await client.graphql({
      query: reviewsByCompanyID,
      variables: { companyID }
    });
    return data.reviewsByCompanyID.items;
  };

  const fetchCompanyHighlights = async (companyID) => {
    const { data } = await client.graphql({
      query: highlightsByCompanyID,
      variables: { companyID }
    });
    return data.highlightsByCompanyID.items;
  };

  const calculateAverageRating = (reviews, ratingType) => {
    if (!reviews || reviews.length === 0) return 0;
    const sum = reviews.reduce((acc, review) => acc + review[ratingType], 0);
    return Math.round(sum / reviews.length);
  };

  const selectRandomAttachments = (attachments, count) => {
    const shuffled = attachments.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const getAttachmentsFromReviewsAndHighlights = async (companyID) => {
    const reviews = await fetchCompanyReviews(companyID);
    const highlights = await fetchCompanyHighlights(companyID);

    const reviewAttachments = reviews.flatMap(review => review.attachmentkey.map(att => `https://media.spiggl.com/public/${att}`));
    const highlightAttachments = highlights.flatMap(highlight => highlight.attachmentkey.map(att => `https://media.spiggl.com/public/${att}`));

    const allAttachments = [...reviewAttachments, ...highlightAttachments];
    return selectRandomAttachments(allAttachments, 4);
  };

  const performSearch = async () => {
    setLoading(true);
    const filteredContractors = await searchContractors(currentZipcode, type, selectedRadius.value);

    const contractorsWithRatingsAndHighlights = await Promise.all(filteredContractors.map(async (contractor) => {
      const reviews = await fetchCompanyReviews(contractor.id);
      const highlights = await fetchCompanyHighlights(contractor.id);
      const randomAttachments = await getAttachmentsFromReviewsAndHighlights(contractor.id);

      return {
        ...contractor,
        averageQuality: calculateAverageRating(reviews, 'quality'),
        averageTimeliness: calculateAverageRating(reviews, 'timeliness'),
        averageCost: calculateAverageRating(reviews, 'cost'),
        averageOverall: calculateAverageRating(reviews, 'overall'),
        attachments: randomAttachments,
        specialties: await fetchSpecialtiesForCompany(contractor.id)
      };
    }));

    contractorsWithRatingsAndHighlights.sort((a, b) => b.averageOverall - a.averageOverall);

    const contractorsFilteredByPrice = filterByPriceRange(contractorsWithRatingsAndHighlights);
    const contractorsFilteredByRatings = filterByRatings(contractorsFilteredByPrice);

    setCompanies(contractorsFilteredByRatings);

    const highestStartingPrice = Math.max(...contractorsFilteredByRatings.map(company => parseInt(company.highRange, 10)));
    setHighestPrice(highestStartingPrice);

    setTotalPages(Math.ceil(contractorsFilteredByRatings.length / resultsPerPage));
    setLoading(false);
  };

  useEffect(() => {
    performSearch();
  }, [type, currentZipcode, selectedRadius, selectedSpecialties, selectedPriceRange, selectedRatings]);

  const onRadiusChange = (radiusValue) => {
    setSelectedRadius({ label: `${radiusValue} miles`, value: radiusValue });
  };

  const onSpecialtiesChange = (specialties) => {
    setSelectedSpecialties(specialties);
  };

  const onPriceRangeChange = (priceRange) => {
    setSelectedPriceRange(priceRange);
  };

  const onRatingsChange = (ratings) => {
    setSelectedRatings(ratings);
  };

  const onZipcodeChange = (zipcodeValue) => {
    setCurrentZipcode(zipcodeValue);
  };

  const onResetFilters = () => {
    setSelectedRadius({ label: '10 miles', value: 10 });
    setSelectedSpecialties([]);
    setSelectedPriceRange([0, highestPrice]);
    setSelectedRatings({ overall: [0, 5], quality: [0, 5], cost: [0, 5], timeliness: [0, 5] });
    performSearch();
  };

  const renderStars = (rating) => {
    return [...Array(5)].map((e, i) => (
      <span key={i} className={`star ${i < rating ? 'filled' : 'empty'}`}>★</span>
    ));
  };

  const createDefaultProfileImage = (user) => {
    const firstChar = user ? user.charAt(0).toUpperCase() : '';
    return `data:image/svg+xml;base64,${btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150">
      <circle cx="75" cy="75" r="75" fill="#23313d"/>
      <text x="50%" y="50%" font-family="Arial, sans-serif" font-size="64" dy=".3em" fill="#FFF" text-anchor="middle">${firstChar}</text>
    </svg>`)}`;
  };

  const indexOfLastCompany = currentPage * resultsPerPage;
  const indexOfFirstCompany = indexOfLastCompany - resultsPerPage;
  const currentCompanies = companies.slice(indexOfFirstCompany, indexOfLastCompany);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(companies.length / resultsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  return (
    <div className="search-results">
      <SearchHeader onRadiusChange={onRadiusChange} onSpecialtiesChange={onSpecialtiesChange} onPriceRangeChange={onPriceRangeChange} onRatingsChange={onRatingsChange} onZipcodeChange={onZipcodeChange} onResetFilters={onResetFilters} highestPrice={highestPrice} />
      <Helmet>
        <title>{type} in {currentZipcode}</title>
      </Helmet>
      <div className="search-results-header">
        <h2 className="search-results-title">
          Search results for {type} within {selectedRadius.label} of {currentZipcode}
        </h2>
        <div className="results-amount">
          {companies.length} results
        </div>
        {loading ? (
          <div className="skeleton-loader">
            {[...Array(5)].map((_, index) => (
              <div key={index} className="skeleton-container">
                <Skeleton height={200} />
                <Skeleton count={3} />
              </div>
            ))}
          </div>
        ) : companies.length > 0 ? (
          <>
            <div className="pagination-info">
              Showing {(currentPage - 1) * resultsPerPage + 1} - {Math.min(currentPage * resultsPerPage, companies.length)} of {companies.length}
            </div>
            <div className="searched-results-box">
              {currentCompanies.map((company, index) => (
                <div key={index} className="search-results-company-profile">
                  <div className="past-review-header">
                    {company.profilepicturekey ? (
                      <img src={`https://media.spiggl.com/public/${company.profilepicturekey}`} alt="Company Profile" className="company-searched-picture"/>
                    ) : (
                      <img src={createDefaultProfileImage(company.companyUser)} alt="Default Profile" className="company-searched-picture"/>
                    )}
                    <div className="searched-company-info">
                      <span className="searched-company-id" onClick={() => navigate(`/contractor/${company.companyUser}`)}>
                        {company.companyName ? <strong>{company.companyName}</strong> : company.companyUser}
                      </span>
                      <div className="searched-company-location">
                        <img src="/icons/location.png" alt="Location Icon" className="searched-location-icon" />
                        {company.town}, {company.state}
                      </div>
                      <div className="company-starting-price">Starting at ${company.lowRange}</div>
                    </div>
                  </div>
                  <div className="past-review-title">
                  </div>
                  <div className="user-review-body">
                    <div className="star-ratings-container">
                      <div className="user-review-ratings">
                        <div className="user-star-ratings">
                          <span>Quality:</span>
                          {renderStars(company.averageQuality)}
                        </div>
                        <div className="user-star-ratings">
                          <span>Cost:</span>
                          {renderStars(company.averageCost)}
                        </div>
                        <div className="user-star-ratings">
                          <span>Timeliness:</span>
                          {renderStars(company.averageTimeliness)}
                        </div>
                        <div className="user-star-ratings">
                          <span>Overall:</span>
                          {renderStars(company.averageOverall)}
                        </div>
                      </div>
                    </div>
                    <div className="searched-attachments">
                      {company.attachments && company.attachments.map((attachment, index) => (
                        <div key={index} className="attachment-wrapper" onClick={() => openModal(index, company.attachments)}>
                          <img src={attachment} alt="Company Highlight" className="searched-attachments__icon" />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="past-review-company-type">
                    {company.companyType.map((type, idx) => (
                      <div key={idx}>
                        <div>
                          <strong className="company-type">
                            <img src={`/icons/contractor/${type.toLowerCase().replace(/\s+/g, '-')}.png`} alt={`${type} Icon`} />
                            {type}
                          </strong>
                          <div className="specialties">
                            {company.specialties
                              .filter(specialty => specialty.type === type)
                              .map((specialty, index, array) => (
                                <span key={index}>
                                  {specialty.specialties.join(', ')}
                                  {index !== array.length - 1 ? ', ' : ''}
                                </span>
                              ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="searched-pagination">
              {pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageClick(pageNumber)}
                  className={`searched-page-number ${currentPage === pageNumber ? 'active' : ''}`}
                >
                  {pageNumber}
                </button>
              ))}
            </div>
          </>
        ) : (
          <div className="no-searched-results">
            <div className="no-searched-results-text">
              <h3>No results</h3>
              <p>There are no contractors for this search criteria.</p>
              <p>Please try adjusting the distance for more results or go back to search page.</p>
            </div>
          </div>
        )}
      </div>
      <Footer />
      <ImageModal
        show={showModal}
        attachments={currentAttachments}
        selectedIndex={selectedImageIndex}
        onClose={closeModal}
        onNext={() => setSelectedImageIndex((prevIndex) => (prevIndex + 1) % currentAttachments.length)}
        onPrev={() => setSelectedImageIndex((prevIndex) => (prevIndex - 1 + currentAttachments.length) % currentAttachments.length)}
      />
    </div>
  );  
};

export default SearchResults;
