import React, { useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { useNavigate } from 'react-router-dom';
import * as queries from '../../../../graphQL/queries';
import * as mutations from '../../../../graphQL/mutations';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './CompanyInvoices.css';

const CompanyInvoices = ({ userID }) => {
  const [companyData, setCompanyData] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [userNames, setUserNames] = useState({});
  const [loading, setLoading] = useState(true);
  const client = generateClient();
  const navigate = useNavigate();
  const pendingIcon = '/icons/pending.png';
  const completeIcon = '/icons/checkmark.png';

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const { data } = await client.graphql({
          query: queries.getCompany,
          variables: { id: userID }
        });

        if (data.getCompany) {
          setCompanyData(data.getCompany);
        } else {
          console.error('Company not found with id:', userID);
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    const fetchInvoices = async () => {
      try {
        const invoiceData = await client.graphql({
          query: queries.invoicesByCompanyID,
          variables: { companyID: userID }
        });

        const invoices = invoiceData.data.invoicesByCompanyID.items.map(invoice => ({
          ...invoice,
          status: invoice.totalAmount === 0 ? 'Complete' : invoice.status,
          payments: invoice.payments.map(payment => parseFloat(payment))
        }));

        invoices.sort((a, b) => {
          if (a.status === b.status) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          }
          return a.status === 'Pending' ? -1 : 1;
        });

        setInvoices(invoices);

        const userIDs = invoices.map(invoice => invoice.userID);
        const uniqueUserIDs = [...new Set(userIDs)];

        const userNames = await Promise.all(uniqueUserIDs.map(async (userID) => {
          try {
            const { data } = await client.graphql({
              query: queries.getUser,
              variables: { id: userID }
            });
            return { [userID]: data.getUser.name || data.getUser.user };
          } catch (error) {
            console.error('Error fetching user data:', error);
            return { [userID]: 'Unknown User' };
          }
        }));

        const userNamesMap = userNames.reduce((acc, curr) => ({ ...acc, ...curr }), {});
        setUserNames(userNamesMap);
        setLoading(false); 
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    };

    fetchCompanyData();
    fetchInvoices();
  }, [userID, client]);

  const handleInvoiceClick = (invoiceID) => {
    navigate(`/contractor/${companyData.companyUser}/invoice-dash/invoice/${invoiceID}`);
  };

  const invokeInvoiceEmailLambda = async (recipientEmail, companyName, invoiceDetails, invoiceUrl) => {
    const API_ENDPOINT = process.env.REACT_APP_EMAIL_INVOICE_NOTIFICATION_API;
  
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recipientEmail,
          companyName,
          invoiceDetails,
          invoiceUrl,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to send invoice notification');
      }
  
      console.log('Invoice notification sent successfully');
    } catch (error) {
      console.error('Error sending invoice notification:', error);
    }
  }; 

  const handleResendInvoice = async (invoice) => {
    const currentDate = new Date();
    const { userID, companyID, amount, frequency, oneTimePayment, numberOfPayments, invoiceAttachmentKey, resendPayments } = invoice;
    let dueDates = [];
    let payments = resendPayments;
  
    try {
      const userResponse = await client.graphql({
        query: queries.getUser,
        variables: { id: userID },
      });
  
      const user = userResponse.data.getUser;
  
      if (!user || !user.email) {
        throw new Error(`User email not found for userID: ${userID}`);
      }
  
      const recipientEmail = user.email;
  
      if (oneTimePayment) {
        const createdDate = new Date(invoice.createdAt);
        const firstDueDateLog = new Date(invoice.dueDateLog[0]);
  
        if (isNaN(createdDate) || isNaN(firstDueDateLog)) {
          throw new Error("Invalid dates for invoice");
        }
  
        const daysDifference = (firstDueDateLog - createdDate) / (1000 * 60 * 60 * 24);
        const newDueDate = new Date(currentDate);
        newDueDate.setDate(newDueDate.getDate() + daysDifference);
  
        if (isNaN(newDueDate.getTime())) {
          throw new Error("Invalid new due date calculated");
        }
  
        dueDates = [newDueDate.toISOString().split('T')[0]];
        payments = [amount];
        const newDueDateLog = [...invoice.dueDateLog, newDueDate.toISOString().split('T')[0]];
  
        const input = {
          userID,
          companyID,
          totalAmount: amount,
          amount,
          oneTimePayment,
          frequency,
          numberOfPayments,
          status: 'Pending',
          invoiceAttachmentKey,
          payments,
          dueDates,
          resendPayments,
          dueDateLog: newDueDateLog,
          resent: false,
          readStatus: false,
        };
  
        await client.graphql({
          query: mutations.createInvoices,
          variables: { input },
        });
  
        await client.graphql({
          query: mutations.updateInvoices,
          variables: { input: { id: invoice.id, resent: true } },
        });
  
        const invoiceUrl = `https://spiggl.com/user/${invoice.userID}/invoice-dash/invoice/${invoice.id}`;
        const invoiceDetails = {
          totalAmount: formatAmount(amount),
          numberOfPayments,
          payments: payments.map((payment, index) => ({
            amount: formatAmount(payment),
            due: dueDates[index],
          })),
        };
  
        await invokeInvoiceEmailLambda(recipientEmail, companyData.companyName, invoiceDetails, invoiceUrl);
  
        setInvoices(prevInvoices =>
          prevInvoices.map(inv => (inv.id === invoice.id ? { ...inv, resent: true } : inv))
        );
      } else {
        dueDates = calculateDueDates(numberOfPayments, frequency);
  
        if (!dueDates.length) {
          throw new Error("No valid due dates generated for the invoice.");
        }
  
        const input = {
          userID,
          companyID,
          totalAmount: amount,
          amount,
          oneTimePayment,
          frequency,
          numberOfPayments,
          status: 'Pending',
          invoiceAttachmentKey,
          payments,
          dueDates,
          resendPayments,
          dueDateLog: [...invoice.dueDateLog, ...dueDates],
          resent: false,
          readStatus: false,
        };
  
        await client.graphql({
          query: mutations.createInvoices,
          variables: { input },
        });
  
        await client.graphql({
          query: mutations.updateInvoices,
          variables: { input: { id: invoice.id, resent: true } },
        });
  
        const invoiceUrl = `https://spiggl.com/user/${invoice.userID}/invoice-dash/invoice/${invoice.id}`;
        const invoiceDetails = {
          totalAmount: formatAmount(amount),
          numberOfPayments,
          payments: payments.map((payment, index) => ({
            amount: formatAmount(payment),
            due: dueDates[index],
          })),
        };
  
        await invokeInvoiceEmailLambda(recipientEmail, companyData.companyName, invoiceDetails, invoiceUrl);
  
        setInvoices(prevInvoices =>
          prevInvoices.map(inv => (inv.id === invoice.id ? { ...inv, resent: true } : inv))
        );
      }
    } catch (err) {
      console.error("Error resending invoice:", err);
      alert("Error resending invoice");
    }
  };  

  const formatAmount = (amount) => {
    const value = parseFloat(amount);
    return isNaN(value) ? '' : value.toFixed(2);
  };

  const calculateDueDates = (numberOfPayments, frequency) => {
    return Array.from({ length: numberOfPayments }, (_, index) => calculateDueDateByFrequency(index + 1, frequency));
  };

  const calculateDueDateByFrequency = (paymentNumber, frequency) => {
    const date = new Date();
    switch (frequency) {
      case 'Weekly':
        date.setDate(date.getDate() + (7 * paymentNumber));
        break;
      case 'Bi-Weekly':
        date.setDate(date.getDate() + (14 * paymentNumber));
        break;
      case 'Monthly':
        date.setMonth(date.getMonth() + paymentNumber);
        break;
      case 'Bi-Monthly':
        date.setMonth(date.getMonth() + (2 * paymentNumber));
        break;
      case 'Quarterly':
        date.setMonth(date.getMonth() + (3 * paymentNumber));
        break;
      case 'Yearly':
        date.setFullYear(date.getFullYear() + paymentNumber);
        break;
      default:
        break;
    }
    return date.toISOString().split('T')[0];
  };

  const formatAmountWithCommas = (amount) => {
    return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div className="invoices">
      <div className="invoices-header">
        <div className="invoices-title">Invoices</div>
        <div className="total-invoices">Total: {loading ? <Skeleton width={50} /> : invoices.length}</div>
      </div>
      <div className="invoices-list">
        {loading ? (
          <Skeleton count={5} height={50} />
        ) : (
          invoices.map((invoice) => {
            const userName = userNames[invoice.userID];
    
            return (
              <div 
                className={`invoice-item ${!invoice.readStatus ? "unread" : ""}`} 
                key={invoice.id} 
                onClick={() => handleInvoiceClick(invoice.id)}
              >
                <div className="invoice-user-name">{userName}</div>
                <div className="invoice-payment-section">
                  {invoice.status === 'Complete' && !invoice.resent ? (
                    <>
                      <div className="resend-button-container">
                        <button className="resend-button" onClick={(e) => { e.stopPropagation(); handleResendInvoice(invoice); }}>
                          Resend
                        </button>
                      </div>
                      <div className="total-amount small">
                        ${formatAmountWithCommas(invoice.amount)}
                      </div>
                    </>
                  ) : (
                    <div className="total-amount normal">
                      {invoice.status === 'Complete' && invoice.resent ? (
                        <div className="completed-resent">${formatAmountWithCommas(invoice.amount)}</div>
                      ) : (
                        invoice.totalAmount !== 0 && <div className="amount-due">${formatAmountWithCommas(invoice.totalAmount)}</div>
                      )}
                    </div>
                  )}
                </div>
                <div className={`status-icon ${invoice.status.toLowerCase()}`}>
                  <img src={invoice.status === 'Pending' ? pendingIcon : completeIcon} alt={invoice.status} />
                </div>
                <div className="arrow-icon">❯</div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};  

export default CompanyInvoices;
