/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      requestDate
      startTime
      duration
      description
      isConfirmed
      isPending
      userID
      companyID
      address
      putOff
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      requestDate
      startTime
      duration
      description
      isConfirmed
      isPending
      userID
      companyID
      address
      putOff
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      requestDate
      startTime
      duration
      description
      isConfirmed
      isPending
      userID
      companyID
      address
      putOff
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      conversationID
      userID
      reportedUserID
      reasonGiven
      reasonWhy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      conversationID
      userID
      reportedUserID
      reasonGiven
      reasonWhy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      conversationID
      userID
      reportedUserID
      reasonGiven
      reasonWhy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPaymentMethod = /* GraphQL */ `
  mutation CreatePaymentMethod(
    $input: CreatePaymentMethodInput!
    $condition: ModelPaymentMethodConditionInput
  ) {
    createPaymentMethod(input: $input, condition: $condition) {
      id
      stripeCardToken
      nameOnCard
      brand
      userID
      last4
      expMonth
      expYear
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePaymentMethod = /* GraphQL */ `
  mutation UpdatePaymentMethod(
    $input: UpdatePaymentMethodInput!
    $condition: ModelPaymentMethodConditionInput
  ) {
    updatePaymentMethod(input: $input, condition: $condition) {
      id
      stripeCardToken
      nameOnCard
      brand
      userID
      last4
      expMonth
      expYear
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePaymentMethod = /* GraphQL */ `
  mutation DeletePaymentMethod(
    $input: DeletePaymentMethodInput!
    $condition: ModelPaymentMethodConditionInput
  ) {
    deletePaymentMethod(input: $input, condition: $condition) {
      id
      stripeCardToken
      nameOnCard
      brand
      userID
      last4
      expMonth
      expYear
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      id
      day
      allDay
      from
      to
      companyID
      userID
      address
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      id
      day
      allDay
      from
      to
      companyID
      userID
      address
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      id
      day
      allDay
      from
      to
      companyID
      userID
      address
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSavedReview = /* GraphQL */ `
  mutation CreateSavedReview(
    $input: CreateSavedReviewInput!
    $condition: ModelSavedReviewConditionInput
  ) {
    createSavedReview(input: $input, condition: $condition) {
      id
      reviewID
      savedID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSavedReview = /* GraphQL */ `
  mutation UpdateSavedReview(
    $input: UpdateSavedReviewInput!
    $condition: ModelSavedReviewConditionInput
  ) {
    updateSavedReview(input: $input, condition: $condition) {
      id
      reviewID
      savedID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSavedReview = /* GraphQL */ `
  mutation DeleteSavedReview(
    $input: DeleteSavedReviewInput!
    $condition: ModelSavedReviewConditionInput
  ) {
    deleteSavedReview(input: $input, condition: $condition) {
      id
      reviewID
      savedID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessages = /* GraphQL */ `
  mutation CreateMessages(
    $input: CreateMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    createMessages(input: $input, condition: $condition) {
      id
      conversationID
      senderID
      receiverID
      messageContent
      readStatus
      timeStamp
      lastSent
      receiverDeleted
      senderDeleted
      attachmentkey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessages = /* GraphQL */ `
  mutation UpdateMessages(
    $input: UpdateMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    updateMessages(input: $input, condition: $condition) {
      id
      conversationID
      senderID
      receiverID
      messageContent
      readStatus
      timeStamp
      lastSent
      receiverDeleted
      senderDeleted
      attachmentkey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessages = /* GraphQL */ `
  mutation DeleteMessages(
    $input: DeleteMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    deleteMessages(input: $input, condition: $condition) {
      id
      conversationID
      senderID
      receiverID
      messageContent
      readStatus
      timeStamp
      lastSent
      receiverDeleted
      senderDeleted
      attachmentkey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBankMethod = /* GraphQL */ `
  mutation CreateBankMethod(
    $input: CreateBankMethodInput!
    $condition: ModelBankMethodConditionInput
  ) {
    createBankMethod(input: $input, condition: $condition) {
      id
      stripeBankToken
      last4
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBankMethod = /* GraphQL */ `
  mutation UpdateBankMethod(
    $input: UpdateBankMethodInput!
    $condition: ModelBankMethodConditionInput
  ) {
    updateBankMethod(input: $input, condition: $condition) {
      id
      stripeBankToken
      last4
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBankMethod = /* GraphQL */ `
  mutation DeleteBankMethod(
    $input: DeleteBankMethodInput!
    $condition: ModelBankMethodConditionInput
  ) {
    deleteBankMethod(input: $input, condition: $condition) {
      id
      stripeBankToken
      last4
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHighlight = /* GraphQL */ `
  mutation CreateHighlight(
    $input: CreateHighlightInput!
    $condition: ModelHighlightConditionInput
  ) {
    createHighlight(input: $input, condition: $condition) {
      id
      title
      description
      attachmentkey
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHighlight = /* GraphQL */ `
  mutation UpdateHighlight(
    $input: UpdateHighlightInput!
    $condition: ModelHighlightConditionInput
  ) {
    updateHighlight(input: $input, condition: $condition) {
      id
      title
      description
      attachmentkey
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHighlight = /* GraphQL */ `
  mutation DeleteHighlight(
    $input: DeleteHighlightInput!
    $condition: ModelHighlightConditionInput
  ) {
    deleteHighlight(input: $input, condition: $condition) {
      id
      title
      description
      attachmentkey
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      email
      title
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      email
      title
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      email
      title
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSpecialty = /* GraphQL */ `
  mutation CreateSpecialty(
    $input: CreateSpecialtyInput!
    $condition: ModelSpecialtyConditionInput
  ) {
    createSpecialty(input: $input, condition: $condition) {
      id
      type
      specialties
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSpecialty = /* GraphQL */ `
  mutation UpdateSpecialty(
    $input: UpdateSpecialtyInput!
    $condition: ModelSpecialtyConditionInput
  ) {
    updateSpecialty(input: $input, condition: $condition) {
      id
      type
      specialties
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSpecialty = /* GraphQL */ `
  mutation DeleteSpecialty(
    $input: DeleteSpecialtyInput!
    $condition: ModelSpecialtyConditionInput
  ) {
    deleteSpecialty(input: $input, condition: $condition) {
      id
      type
      specialties
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInvoices = /* GraphQL */ `
  mutation CreateInvoices(
    $input: CreateInvoicesInput!
    $condition: ModelInvoicesConditionInput
  ) {
    createInvoices(input: $input, condition: $condition) {
      id
      totalAmount
      grandAmount
      status
      frequency
      companyID
      userID
      invoiceAttachmentKey
      dueDates
      payments
      feeAmountIncluded
      numberOfPayments
      oneTimePayment
      amount
      latePayments
      resendPayments
      paymentDate
      resent
      readStatus
      dueDateLog
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInvoices = /* GraphQL */ `
  mutation UpdateInvoices(
    $input: UpdateInvoicesInput!
    $condition: ModelInvoicesConditionInput
  ) {
    updateInvoices(input: $input, condition: $condition) {
      id
      totalAmount
      grandAmount
      status
      frequency
      companyID
      userID
      invoiceAttachmentKey
      dueDates
      payments
      feeAmountIncluded
      numberOfPayments
      oneTimePayment
      amount
      latePayments
      resendPayments
      paymentDate
      resent
      readStatus
      dueDateLog
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInvoices = /* GraphQL */ `
  mutation DeleteInvoices(
    $input: DeleteInvoicesInput!
    $condition: ModelInvoicesConditionInput
  ) {
    deleteInvoices(input: $input, condition: $condition) {
      id
      totalAmount
      grandAmount
      status
      frequency
      companyID
      userID
      invoiceAttachmentKey
      dueDates
      payments
      feeAmountIncluded
      numberOfPayments
      oneTimePayment
      amount
      latePayments
      resendPayments
      paymentDate
      resent
      readStatus
      dueDateLog
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      title
      description
      quality
      timeliness
      cost
      overall
      attachmentkey
      companyID
      userID
      SavedReviews {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      title
      description
      quality
      timeliness
      cost
      overall
      attachmentkey
      companyID
      userID
      SavedReviews {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      title
      description
      quality
      timeliness
      cost
      overall
      attachmentkey
      companyID
      userID
      SavedReviews {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      companyUser
      companyName
      companyType
      zipcode
      email
      phone
      companyWebsite
      bio
      Reviews {
        nextToken
        __typename
      }
      Highlights {
        nextToken
        __typename
      }
      profilepicturekey
      backgroundpicturekey
      Invoices {
        nextToken
        __typename
      }
      BankMethod {
        nextToken
        __typename
      }
      town
      state
      Schedules {
        nextToken
        __typename
      }
      Specialties {
        nextToken
        __typename
      }
      emailPrivate
      phonePrivate
      blocked
      lowRange
      highRange
      stripeAccountId
      lowPrivate
      highPrivate
      Requests {
        nextToken
        __typename
      }
      messageNotifications
      paymentNotifications
      requestNotifications
      pushMessageNotifications
      pushPaymentNotifications
      pushRequestNotifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      companyUser
      companyName
      companyType
      zipcode
      email
      phone
      companyWebsite
      bio
      Reviews {
        nextToken
        __typename
      }
      Highlights {
        nextToken
        __typename
      }
      profilepicturekey
      backgroundpicturekey
      Invoices {
        nextToken
        __typename
      }
      BankMethod {
        nextToken
        __typename
      }
      town
      state
      Schedules {
        nextToken
        __typename
      }
      Specialties {
        nextToken
        __typename
      }
      emailPrivate
      phonePrivate
      blocked
      lowRange
      highRange
      stripeAccountId
      lowPrivate
      highPrivate
      Requests {
        nextToken
        __typename
      }
      messageNotifications
      paymentNotifications
      requestNotifications
      pushMessageNotifications
      pushPaymentNotifications
      pushRequestNotifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      companyUser
      companyName
      companyType
      zipcode
      email
      phone
      companyWebsite
      bio
      Reviews {
        nextToken
        __typename
      }
      Highlights {
        nextToken
        __typename
      }
      profilepicturekey
      backgroundpicturekey
      Invoices {
        nextToken
        __typename
      }
      BankMethod {
        nextToken
        __typename
      }
      town
      state
      Schedules {
        nextToken
        __typename
      }
      Specialties {
        nextToken
        __typename
      }
      emailPrivate
      phonePrivate
      blocked
      lowRange
      highRange
      stripeAccountId
      lowPrivate
      highPrivate
      Requests {
        nextToken
        __typename
      }
      messageNotifications
      paymentNotifications
      requestNotifications
      pushMessageNotifications
      pushPaymentNotifications
      pushRequestNotifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      user
      name
      email
      phone
      profilepicturekey
      backgroundpicturekey
      Reviews {
        nextToken
        __typename
      }
      Invoices {
        nextToken
        __typename
      }
      PaymentMethod {
        nextToken
        __typename
      }
      blocked
      Requests {
        nextToken
        __typename
      }
      messageNotifications
      paymentNotifications
      requestSatusNotifications
      invoiceNotifications
      pushMessageNotifications
      pushPaymentNotifications
      pushRequestSatusNotifications
      pushInvoiceNotifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      user
      name
      email
      phone
      profilepicturekey
      backgroundpicturekey
      Reviews {
        nextToken
        __typename
      }
      Invoices {
        nextToken
        __typename
      }
      PaymentMethod {
        nextToken
        __typename
      }
      blocked
      Requests {
        nextToken
        __typename
      }
      messageNotifications
      paymentNotifications
      requestSatusNotifications
      invoiceNotifications
      pushMessageNotifications
      pushPaymentNotifications
      pushRequestSatusNotifications
      pushInvoiceNotifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      user
      name
      email
      phone
      profilepicturekey
      backgroundpicturekey
      Reviews {
        nextToken
        __typename
      }
      Invoices {
        nextToken
        __typename
      }
      PaymentMethod {
        nextToken
        __typename
      }
      blocked
      Requests {
        nextToken
        __typename
      }
      messageNotifications
      paymentNotifications
      requestSatusNotifications
      invoiceNotifications
      pushMessageNotifications
      pushPaymentNotifications
      pushRequestSatusNotifications
      pushInvoiceNotifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
