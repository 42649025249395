/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateRequest = /* GraphQL */ `
  subscription OnCreateRequest($filter: ModelSubscriptionRequestFilterInput) {
    onCreateRequest(filter: $filter) {
      id
      requestDate
      startTime
      duration
      description
      isConfirmed
      isPending
      userID
      companyID
      address
      putOff
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRequest = /* GraphQL */ `
  subscription OnUpdateRequest($filter: ModelSubscriptionRequestFilterInput) {
    onUpdateRequest(filter: $filter) {
      id
      requestDate
      startTime
      duration
      description
      isConfirmed
      isPending
      userID
      companyID
      address
      putOff
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRequest = /* GraphQL */ `
  subscription OnDeleteRequest($filter: ModelSubscriptionRequestFilterInput) {
    onDeleteRequest(filter: $filter) {
      id
      requestDate
      startTime
      duration
      description
      isConfirmed
      isPending
      userID
      companyID
      address
      putOff
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateReport = /* GraphQL */ `
  subscription OnCreateReport($filter: ModelSubscriptionReportFilterInput) {
    onCreateReport(filter: $filter) {
      id
      conversationID
      userID
      reportedUserID
      reasonGiven
      reasonWhy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateReport = /* GraphQL */ `
  subscription OnUpdateReport($filter: ModelSubscriptionReportFilterInput) {
    onUpdateReport(filter: $filter) {
      id
      conversationID
      userID
      reportedUserID
      reasonGiven
      reasonWhy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteReport = /* GraphQL */ `
  subscription OnDeleteReport($filter: ModelSubscriptionReportFilterInput) {
    onDeleteReport(filter: $filter) {
      id
      conversationID
      userID
      reportedUserID
      reasonGiven
      reasonWhy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePaymentMethod = /* GraphQL */ `
  subscription OnCreatePaymentMethod(
    $filter: ModelSubscriptionPaymentMethodFilterInput
  ) {
    onCreatePaymentMethod(filter: $filter) {
      id
      stripeCardToken
      nameOnCard
      brand
      userID
      last4
      expMonth
      expYear
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePaymentMethod = /* GraphQL */ `
  subscription OnUpdatePaymentMethod(
    $filter: ModelSubscriptionPaymentMethodFilterInput
  ) {
    onUpdatePaymentMethod(filter: $filter) {
      id
      stripeCardToken
      nameOnCard
      brand
      userID
      last4
      expMonth
      expYear
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePaymentMethod = /* GraphQL */ `
  subscription OnDeletePaymentMethod(
    $filter: ModelSubscriptionPaymentMethodFilterInput
  ) {
    onDeletePaymentMethod(filter: $filter) {
      id
      stripeCardToken
      nameOnCard
      brand
      userID
      last4
      expMonth
      expYear
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSchedule = /* GraphQL */ `
  subscription OnCreateSchedule($filter: ModelSubscriptionScheduleFilterInput) {
    onCreateSchedule(filter: $filter) {
      id
      day
      allDay
      from
      to
      companyID
      userID
      address
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSchedule = /* GraphQL */ `
  subscription OnUpdateSchedule($filter: ModelSubscriptionScheduleFilterInput) {
    onUpdateSchedule(filter: $filter) {
      id
      day
      allDay
      from
      to
      companyID
      userID
      address
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSchedule = /* GraphQL */ `
  subscription OnDeleteSchedule($filter: ModelSubscriptionScheduleFilterInput) {
    onDeleteSchedule(filter: $filter) {
      id
      day
      allDay
      from
      to
      companyID
      userID
      address
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSavedReview = /* GraphQL */ `
  subscription OnCreateSavedReview(
    $filter: ModelSubscriptionSavedReviewFilterInput
  ) {
    onCreateSavedReview(filter: $filter) {
      id
      reviewID
      savedID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSavedReview = /* GraphQL */ `
  subscription OnUpdateSavedReview(
    $filter: ModelSubscriptionSavedReviewFilterInput
  ) {
    onUpdateSavedReview(filter: $filter) {
      id
      reviewID
      savedID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSavedReview = /* GraphQL */ `
  subscription OnDeleteSavedReview(
    $filter: ModelSubscriptionSavedReviewFilterInput
  ) {
    onDeleteSavedReview(filter: $filter) {
      id
      reviewID
      savedID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMessages = /* GraphQL */ `
  subscription OnCreateMessages($filter: ModelSubscriptionMessagesFilterInput) {
    onCreateMessages(filter: $filter) {
      id
      conversationID
      senderID
      receiverID
      messageContent
      readStatus
      timeStamp
      lastSent
      receiverDeleted
      senderDeleted
      attachmentkey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMessages = /* GraphQL */ `
  subscription OnUpdateMessages($filter: ModelSubscriptionMessagesFilterInput) {
    onUpdateMessages(filter: $filter) {
      id
      conversationID
      senderID
      receiverID
      messageContent
      readStatus
      timeStamp
      lastSent
      receiverDeleted
      senderDeleted
      attachmentkey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMessages = /* GraphQL */ `
  subscription OnDeleteMessages($filter: ModelSubscriptionMessagesFilterInput) {
    onDeleteMessages(filter: $filter) {
      id
      conversationID
      senderID
      receiverID
      messageContent
      readStatus
      timeStamp
      lastSent
      receiverDeleted
      senderDeleted
      attachmentkey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateBankMethod = /* GraphQL */ `
  subscription OnCreateBankMethod(
    $filter: ModelSubscriptionBankMethodFilterInput
  ) {
    onCreateBankMethod(filter: $filter) {
      id
      stripeBankToken
      last4
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateBankMethod = /* GraphQL */ `
  subscription OnUpdateBankMethod(
    $filter: ModelSubscriptionBankMethodFilterInput
  ) {
    onUpdateBankMethod(filter: $filter) {
      id
      stripeBankToken
      last4
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteBankMethod = /* GraphQL */ `
  subscription OnDeleteBankMethod(
    $filter: ModelSubscriptionBankMethodFilterInput
  ) {
    onDeleteBankMethod(filter: $filter) {
      id
      stripeBankToken
      last4
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateHighlight = /* GraphQL */ `
  subscription OnCreateHighlight(
    $filter: ModelSubscriptionHighlightFilterInput
  ) {
    onCreateHighlight(filter: $filter) {
      id
      title
      description
      attachmentkey
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateHighlight = /* GraphQL */ `
  subscription OnUpdateHighlight(
    $filter: ModelSubscriptionHighlightFilterInput
  ) {
    onUpdateHighlight(filter: $filter) {
      id
      title
      description
      attachmentkey
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteHighlight = /* GraphQL */ `
  subscription OnDeleteHighlight(
    $filter: ModelSubscriptionHighlightFilterInput
  ) {
    onDeleteHighlight(filter: $filter) {
      id
      title
      description
      attachmentkey
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact($filter: ModelSubscriptionContactFilterInput) {
    onCreateContact(filter: $filter) {
      id
      email
      title
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact($filter: ModelSubscriptionContactFilterInput) {
    onUpdateContact(filter: $filter) {
      id
      email
      title
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteContact = /* GraphQL */ `
  subscription OnDeleteContact($filter: ModelSubscriptionContactFilterInput) {
    onDeleteContact(filter: $filter) {
      id
      email
      title
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSpecialty = /* GraphQL */ `
  subscription OnCreateSpecialty(
    $filter: ModelSubscriptionSpecialtyFilterInput
  ) {
    onCreateSpecialty(filter: $filter) {
      id
      type
      specialties
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSpecialty = /* GraphQL */ `
  subscription OnUpdateSpecialty(
    $filter: ModelSubscriptionSpecialtyFilterInput
  ) {
    onUpdateSpecialty(filter: $filter) {
      id
      type
      specialties
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSpecialty = /* GraphQL */ `
  subscription OnDeleteSpecialty(
    $filter: ModelSubscriptionSpecialtyFilterInput
  ) {
    onDeleteSpecialty(filter: $filter) {
      id
      type
      specialties
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateInvoices = /* GraphQL */ `
  subscription OnCreateInvoices($filter: ModelSubscriptionInvoicesFilterInput) {
    onCreateInvoices(filter: $filter) {
      id
      totalAmount
      grandAmount
      status
      frequency
      companyID
      userID
      invoiceAttachmentKey
      dueDates
      payments
      feeAmountIncluded
      numberOfPayments
      oneTimePayment
      amount
      latePayments
      resendPayments
      paymentDate
      resent
      readStatus
      dueDateLog
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateInvoices = /* GraphQL */ `
  subscription OnUpdateInvoices($filter: ModelSubscriptionInvoicesFilterInput) {
    onUpdateInvoices(filter: $filter) {
      id
      totalAmount
      grandAmount
      status
      frequency
      companyID
      userID
      invoiceAttachmentKey
      dueDates
      payments
      feeAmountIncluded
      numberOfPayments
      oneTimePayment
      amount
      latePayments
      resendPayments
      paymentDate
      resent
      readStatus
      dueDateLog
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteInvoices = /* GraphQL */ `
  subscription OnDeleteInvoices($filter: ModelSubscriptionInvoicesFilterInput) {
    onDeleteInvoices(filter: $filter) {
      id
      totalAmount
      grandAmount
      status
      frequency
      companyID
      userID
      invoiceAttachmentKey
      dueDates
      payments
      feeAmountIncluded
      numberOfPayments
      oneTimePayment
      amount
      latePayments
      resendPayments
      paymentDate
      resent
      readStatus
      dueDateLog
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview($filter: ModelSubscriptionReviewFilterInput) {
    onCreateReview(filter: $filter) {
      id
      title
      description
      quality
      timeliness
      cost
      overall
      attachmentkey
      companyID
      userID
      SavedReviews {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview($filter: ModelSubscriptionReviewFilterInput) {
    onUpdateReview(filter: $filter) {
      id
      title
      description
      quality
      timeliness
      cost
      overall
      attachmentkey
      companyID
      userID
      SavedReviews {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview($filter: ModelSubscriptionReviewFilterInput) {
    onDeleteReview(filter: $filter) {
      id
      title
      description
      quality
      timeliness
      cost
      overall
      attachmentkey
      companyID
      userID
      SavedReviews {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onCreateCompany(filter: $filter) {
      id
      companyUser
      companyName
      companyType
      zipcode
      email
      phone
      companyWebsite
      bio
      Reviews {
        nextToken
        __typename
      }
      Highlights {
        nextToken
        __typename
      }
      profilepicturekey
      backgroundpicturekey
      Invoices {
        nextToken
        __typename
      }
      BankMethod {
        nextToken
        __typename
      }
      town
      state
      Schedules {
        nextToken
        __typename
      }
      Specialties {
        nextToken
        __typename
      }
      emailPrivate
      phonePrivate
      blocked
      lowRange
      highRange
      stripeAccountId
      lowPrivate
      highPrivate
      Requests {
        nextToken
        __typename
      }
      messageNotifications
      paymentNotifications
      requestNotifications
      pushMessageNotifications
      pushPaymentNotifications
      pushRequestNotifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onUpdateCompany(filter: $filter) {
      id
      companyUser
      companyName
      companyType
      zipcode
      email
      phone
      companyWebsite
      bio
      Reviews {
        nextToken
        __typename
      }
      Highlights {
        nextToken
        __typename
      }
      profilepicturekey
      backgroundpicturekey
      Invoices {
        nextToken
        __typename
      }
      BankMethod {
        nextToken
        __typename
      }
      town
      state
      Schedules {
        nextToken
        __typename
      }
      Specialties {
        nextToken
        __typename
      }
      emailPrivate
      phonePrivate
      blocked
      lowRange
      highRange
      stripeAccountId
      lowPrivate
      highPrivate
      Requests {
        nextToken
        __typename
      }
      messageNotifications
      paymentNotifications
      requestNotifications
      pushMessageNotifications
      pushPaymentNotifications
      pushRequestNotifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onDeleteCompany(filter: $filter) {
      id
      companyUser
      companyName
      companyType
      zipcode
      email
      phone
      companyWebsite
      bio
      Reviews {
        nextToken
        __typename
      }
      Highlights {
        nextToken
        __typename
      }
      profilepicturekey
      backgroundpicturekey
      Invoices {
        nextToken
        __typename
      }
      BankMethod {
        nextToken
        __typename
      }
      town
      state
      Schedules {
        nextToken
        __typename
      }
      Specialties {
        nextToken
        __typename
      }
      emailPrivate
      phonePrivate
      blocked
      lowRange
      highRange
      stripeAccountId
      lowPrivate
      highPrivate
      Requests {
        nextToken
        __typename
      }
      messageNotifications
      paymentNotifications
      requestNotifications
      pushMessageNotifications
      pushPaymentNotifications
      pushRequestNotifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      user
      name
      email
      phone
      profilepicturekey
      backgroundpicturekey
      Reviews {
        nextToken
        __typename
      }
      Invoices {
        nextToken
        __typename
      }
      PaymentMethod {
        nextToken
        __typename
      }
      blocked
      Requests {
        nextToken
        __typename
      }
      messageNotifications
      paymentNotifications
      requestSatusNotifications
      invoiceNotifications
      pushMessageNotifications
      pushPaymentNotifications
      pushRequestSatusNotifications
      pushInvoiceNotifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      user
      name
      email
      phone
      profilepicturekey
      backgroundpicturekey
      Reviews {
        nextToken
        __typename
      }
      Invoices {
        nextToken
        __typename
      }
      PaymentMethod {
        nextToken
        __typename
      }
      blocked
      Requests {
        nextToken
        __typename
      }
      messageNotifications
      paymentNotifications
      requestSatusNotifications
      invoiceNotifications
      pushMessageNotifications
      pushPaymentNotifications
      pushRequestSatusNotifications
      pushInvoiceNotifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      user
      name
      email
      phone
      profilepicturekey
      backgroundpicturekey
      Reviews {
        nextToken
        __typename
      }
      Invoices {
        nextToken
        __typename
      }
      PaymentMethod {
        nextToken
        __typename
      }
      blocked
      Requests {
        nextToken
        __typename
      }
      messageNotifications
      paymentNotifications
      requestSatusNotifications
      invoiceNotifications
      pushMessageNotifications
      pushPaymentNotifications
      pushRequestSatusNotifications
      pushInvoiceNotifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
