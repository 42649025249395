/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      requestDate
      startTime
      duration
      description
      isConfirmed
      isPending
      userID
      companyID
      address
      putOff
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        requestDate
        startTime
        duration
        description
        isConfirmed
        isPending
        userID
        companyID
        address
        putOff
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const requestsByUserID = /* GraphQL */ `
  query RequestsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    requestsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        requestDate
        startTime
        duration
        description
        isConfirmed
        isPending
        userID
        companyID
        address
        putOff
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const requestsByCompanyID = /* GraphQL */ `
  query RequestsByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    requestsByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        requestDate
        startTime
        duration
        description
        isConfirmed
        isPending
        userID
        companyID
        address
        putOff
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      conversationID
      userID
      reportedUserID
      reasonGiven
      reasonWhy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conversationID
        userID
        reportedUserID
        reasonGiven
        reasonWhy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentMethod = /* GraphQL */ `
  query GetPaymentMethod($id: ID!) {
    getPaymentMethod(id: $id) {
      id
      stripeCardToken
      nameOnCard
      brand
      userID
      last4
      expMonth
      expYear
      isActive
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPaymentMethods = /* GraphQL */ `
  query ListPaymentMethods(
    $filter: ModelPaymentMethodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentMethods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stripeCardToken
        nameOnCard
        brand
        userID
        last4
        expMonth
        expYear
        isActive
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const paymentMethodsByUserID = /* GraphQL */ `
  query PaymentMethodsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentMethodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentMethodsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stripeCardToken
        nameOnCard
        brand
        userID
        last4
        expMonth
        expYear
        isActive
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSchedule = /* GraphQL */ `
  query GetSchedule($id: ID!) {
    getSchedule(id: $id) {
      id
      day
      allDay
      from
      to
      companyID
      userID
      address
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSchedules = /* GraphQL */ `
  query ListSchedules(
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        day
        allDay
        from
        to
        companyID
        userID
        address
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const schedulesByCompanyID = /* GraphQL */ `
  query SchedulesByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulesByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        day
        allDay
        from
        to
        companyID
        userID
        address
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSavedReview = /* GraphQL */ `
  query GetSavedReview($id: ID!) {
    getSavedReview(id: $id) {
      id
      reviewID
      savedID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSavedReviews = /* GraphQL */ `
  query ListSavedReviews(
    $filter: ModelSavedReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSavedReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reviewID
        savedID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const savedReviewsByReviewID = /* GraphQL */ `
  query SavedReviewsByReviewID(
    $reviewID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSavedReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    savedReviewsByReviewID(
      reviewID: $reviewID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reviewID
        savedID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessages = /* GraphQL */ `
  query GetMessages($id: ID!) {
    getMessages(id: $id) {
      id
      conversationID
      senderID
      receiverID
      messageContent
      readStatus
      timeStamp
      lastSent
      receiverDeleted
      senderDeleted
      attachmentkey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conversationID
        senderID
        receiverID
        messageContent
        readStatus
        timeStamp
        lastSent
        receiverDeleted
        senderDeleted
        attachmentkey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBankMethod = /* GraphQL */ `
  query GetBankMethod($id: ID!) {
    getBankMethod(id: $id) {
      id
      stripeBankToken
      last4
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBankMethods = /* GraphQL */ `
  query ListBankMethods(
    $filter: ModelBankMethodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBankMethods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stripeBankToken
        last4
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bankMethodsByCompanyID = /* GraphQL */ `
  query BankMethodsByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBankMethodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bankMethodsByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stripeBankToken
        last4
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHighlight = /* GraphQL */ `
  query GetHighlight($id: ID!) {
    getHighlight(id: $id) {
      id
      title
      description
      attachmentkey
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHighlights = /* GraphQL */ `
  query ListHighlights(
    $filter: ModelHighlightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHighlights(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        attachmentkey
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const highlightsByCompanyID = /* GraphQL */ `
  query HighlightsByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHighlightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    highlightsByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        attachmentkey
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      email
      title
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        title
        message
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSpecialty = /* GraphQL */ `
  query GetSpecialty($id: ID!) {
    getSpecialty(id: $id) {
      id
      type
      specialties
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSpecialties = /* GraphQL */ `
  query ListSpecialties(
    $filter: ModelSpecialtyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        specialties
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const specialtiesByCompanyID = /* GraphQL */ `
  query SpecialtiesByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpecialtyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    specialtiesByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        specialties
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoices = /* GraphQL */ `
  query GetInvoices($id: ID!) {
    getInvoices(id: $id) {
      id
      totalAmount
      grandAmount
      status
      frequency
      companyID
      userID
      invoiceAttachmentKey
      dueDates
      payments
      feeAmountIncluded
      numberOfPayments
      oneTimePayment
      amount
      latePayments
      resendPayments
      paymentDate
      resent
      readStatus
      dueDateLog
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        totalAmount
        grandAmount
        status
        frequency
        companyID
        userID
        invoiceAttachmentKey
        dueDates
        payments
        feeAmountIncluded
        numberOfPayments
        oneTimePayment
        amount
        latePayments
        resendPayments
        paymentDate
        resent
        readStatus
        dueDateLog
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const invoicesByCompanyID = /* GraphQL */ `
  query InvoicesByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        totalAmount
        grandAmount
        status
        frequency
        companyID
        userID
        invoiceAttachmentKey
        dueDates
        payments
        feeAmountIncluded
        numberOfPayments
        oneTimePayment
        amount
        latePayments
        resendPayments
        paymentDate
        resent
        readStatus
        dueDateLog
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const invoicesByUserID = /* GraphQL */ `
  query InvoicesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        totalAmount
        grandAmount
        status
        frequency
        companyID
        userID
        invoiceAttachmentKey
        dueDates
        payments
        feeAmountIncluded
        numberOfPayments
        oneTimePayment
        amount
        latePayments
        resendPayments
        paymentDate
        resent
        readStatus
        dueDateLog
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      title
      description
      quality
      timeliness
      cost
      overall
      attachmentkey
      companyID
      userID
      SavedReviews {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        quality
        timeliness
        cost
        overall
        attachmentkey
        companyID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reviewsByCompanyID = /* GraphQL */ `
  query ReviewsByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        quality
        timeliness
        cost
        overall
        attachmentkey
        companyID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reviewsByUserID = /* GraphQL */ `
  query ReviewsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        quality
        timeliness
        cost
        overall
        attachmentkey
        companyID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      companyUser
      companyName
      companyType
      zipcode
      email
      phone
      companyWebsite
      bio
      Reviews {
        nextToken
        __typename
      }
      Highlights {
        nextToken
        __typename
      }
      profilepicturekey
      backgroundpicturekey
      Invoices {
        nextToken
        __typename
      }
      BankMethod {
        nextToken
        __typename
      }
      town
      state
      Schedules {
        nextToken
        __typename
      }
      Specialties {
        nextToken
        __typename
      }
      emailPrivate
      phonePrivate
      blocked
      lowRange
      highRange
      stripeAccountId
      lowPrivate
      highPrivate
      Requests {
        nextToken
        __typename
      }
      messageNotifications
      paymentNotifications
      requestNotifications
      pushMessageNotifications
      pushPaymentNotifications
      pushRequestNotifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyUser
        companyName
        companyType
        zipcode
        email
        phone
        companyWebsite
        bio
        profilepicturekey
        backgroundpicturekey
        town
        state
        emailPrivate
        phonePrivate
        blocked
        lowRange
        highRange
        stripeAccountId
        lowPrivate
        highPrivate
        messageNotifications
        paymentNotifications
        requestNotifications
        pushMessageNotifications
        pushPaymentNotifications
        pushRequestNotifications
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      user
      name
      email
      phone
      profilepicturekey
      backgroundpicturekey
      Reviews {
        nextToken
        __typename
      }
      Invoices {
        nextToken
        __typename
      }
      PaymentMethod {
        nextToken
        __typename
      }
      blocked
      Requests {
        nextToken
        __typename
      }
      messageNotifications
      paymentNotifications
      requestSatusNotifications
      invoiceNotifications
      pushMessageNotifications
      pushPaymentNotifications
      pushRequestSatusNotifications
      pushInvoiceNotifications
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        name
        email
        phone
        profilepicturekey
        backgroundpicturekey
        blocked
        messageNotifications
        paymentNotifications
        requestSatusNotifications
        invoiceNotifications
        pushMessageNotifications
        pushPaymentNotifications
        pushRequestSatusNotifications
        pushInvoiceNotifications
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
