import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormHeader from '../../../Headers/FormHeader/FormHeader';
import { useAuth } from '../../../../auth/auth';
import { generateClient } from 'aws-amplify/api';
import PaymentLoader from '../../UserInvoice/PaymentLoader/PaymentLoader';
import { Helmet } from 'react-helmet';
import './LinkBank.css';
import * as mutations from '../../../../graphQL/mutations';
import * as queries from '../../../../graphQL/queries';

const LinkBank = () => {
  const [routeNumber, setRouteNumber] = useState('');
  const [acctNumber, setAcctNumber] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const client = generateClient();
  const navigate = useNavigate();
  const { userID } = useAuth();

  const validateNumber = (num, type) => {
    const maxLength = type === 'routing' ? 9 : 12;
    const minLength = type === 'routing' ? 9 : 8;
    const regex = /^\d+$/;
    if (!regex.test(num)) return `${type === 'routing' ? 'Routing' : 'Account'} number must contain only digits.`;
    if (num.length > maxLength) return `${type === 'routing' ? 'Routing' : 'Account'} number cannot exceed ${maxLength} digits.`;
    if (num.length < minLength) return `${type === 'routing' ? 'Routing' : 'Account'} number must be at least ${minLength} digits.`;
    return '';
  };

  const fetchCompanyDetails = async () => {
    try {
      const result = await client.graphql({
        query: queries.getCompany,
        variables: { id: userID },
      });
      const companyData = result.data.getCompany;
      const recipientEmail = companyData.email;
      const companyName = companyData.companyName || companyData.companyUser; 
      return { recipientEmail, companyName };
    } catch (error) {
      console.error('Error fetching company details:', error);
      throw new Error('Failed to fetch company details');
    }
  };

  const handleAddBankMethod = async (e) => {
    e.preventDefault();
    const routeError = validateNumber(routeNumber, 'routing');
    const acctError = validateNumber(acctNumber, 'account');
  
    if (routeError || acctError) {
      setError(routeError || acctError);
      return;
    }
  
    setLoading(true);
  
    try {
      const response = await fetch(process.env.REACT_APP_CREATE_CONNECT_ACCOUNT_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userID,
          accountHolderName: name,
          routingNumber: routeNumber,
          accountNumber: acctNumber,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Stripe account creation response:", data);
  
      if (!data.success || !data.stripeAccountId) {
        throw new Error('Failed to link bank method or missing stripeAccountId.');
      }
  
      await client.graphql({
        query: mutations.updateCompany,
        variables: { input: { id: userID, stripeAccountId: data.stripeAccountId } },
      });
  
      await client.graphql({
        query: mutations.createBankMethod,
        variables: {
          input: {
            stripeBankToken: data.bankToken,
            last4: acctNumber.slice(-4),
            companyID: userID,
          },
        },
      });
  
      const accountLinkResponse = await fetch(`${process.env.REACT_APP_STRIPE_ACCOUNT_LINK_API}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          stripeAccountId: data.stripeAccountId
        }),
      });
  
      const accountLinkData = await accountLinkResponse.json();
      console.log("Account link generation response:", accountLinkData);
  
      if (!accountLinkData.url) {
        throw new Error('Failed to generate account link URL.');
      }
  
      const { recipientEmail, companyName } = await fetchCompanyDetails();
      await invokeNextStepsEmailLambda(recipientEmail, companyName, accountLinkData.url);
  
      setRouteNumber('');
      setAcctNumber('');
      setName('');
      setError('');
      navigate(-1);
    } catch (err) {
      console.error('Error linking bank method:', err);
      setError('Failed to link bank method.');
    } finally {
      setLoading(false);
    }
  };    

  const invokeNextStepsEmailLambda = async (recipientEmail, companyName, url) => {
    const API_ENDPOINT = process.env.REACT_APP_EMAIL_NEXT_STEPS_SETUP;

    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recipientEmail,
          companyName,
          url,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send next steps email');
      }

      console.log('Next steps email sent successfully');
    } catch (error) {
      console.error('Error sending next steps email:', error);
    }
  };

  return (
    <div className="link-bank">
      <FormHeader />
      <Helmet>
        <title>Link Bank</title>
      </Helmet>
      {loading && (
        <div className="payment-loader-overlay">
          <PaymentLoader />
        </div>
      )}
      <div className="link-bank-container">
        <div className="link-bank-label method">
          <div className="link-bank-method">Link Bank</div>
        </div>
        <form className="link-bank-input-container" onSubmit={handleAddBankMethod}>
          <input
            type="text"
            placeholder="Account Holder Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <div className="input-wrapper">
            <input
              type="password"
              placeholder="Routing Number"
              value={routeNumber}
              maxLength="9"
              onChange={(e) => setRouteNumber(e.target.value.replace(/\D/g, '').slice(0, 9))}
              required
            />
          </div>
          <div className="input-wrapper">
            <input
              type="password"
              placeholder="Account Number"
              value={acctNumber}
              maxLength="12"
              onChange={(e) => setAcctNumber(e.target.value.replace(/\D/g, '').slice(0, 12))}
              required
            />
          </div>
          <button className="link-bank-method-button" type="submit" disabled={loading}>
            {loading ? 'Linking...' : 'Link Bank'}
          </button>
          {error && <div className="link-bank-error-message">{error}</div>}
        </form>
      </div>
    </div>
  );
};

export default LinkBank;
