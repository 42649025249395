import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import "./Mainframe.css";
import { Helmet } from 'react-helmet';
import FormHeader from '../../Headers/FormHeader/FormHeader';
import Footer from '../../Footer/Footer';
import contractorTypes from '../ContractorTypes';

const Mainframe = () => {
  const navigate = useNavigate();
  const [selectedContractorType, setSelectedContractorType] = useState(null);
  const [location, setLocation] = useState('');
  const [errors, setErrors] = useState({ contractorType: false, location: false });
  const API_URL = process.env.REACT_APP_PROXY_SERVER_API_URL;
  const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = () => {
    if (!selectedContractorType || !location) {
      setErrors({
        contractorType: !selectedContractorType,
        location: !location,
      });
      return;
    }

    const type = encodeURIComponent(selectedContractorType.value);
    const loc = encodeURIComponent(location);
    navigate(`/search-results/${type}/${loc}`);
  };

  const handleContractorTypeChange = (selectedOption) => {
    setSelectedContractorType(selectedOption);
    setErrors((prev) => ({ ...prev, contractorType: false }));
  };

  const loadOptions = async (inputValue) => {
    try {
      const response = await fetch(`${API_URL}?input=${encodeURIComponent(inputValue)}`);
      const data = await response.json();

      if (response.status !== 200) {
        console.error("Error response:", data);
        throw new Error(data.error || "Error fetching location suggestions");
      }
      if (data.status !== "OK") {
        console.error("Google API error:", data);
        throw new Error("Error fetching location suggestions from Google API");
      }
      return data.predictions.map(prediction => {
        const mainText = prediction.structured_formatting.main_text;
        const secondaryText = prediction.structured_formatting.secondary_text.split(',')[0];
        return {
          label: `${mainText}, ${secondaryText}`,
          value: `${mainText}, ${secondaryText}`,
        };
      });
    } catch (error) {
      console.error("Error fetching location suggestions: ", error);
      return [];
    }
  };

  const convertCoordsToLocation = async (latitude, longitude) => {
    try {
      const geocodeUrl = `${API_GATEWAY_URL}`;
      console.log("Fetching from URL:", geocodeUrl);
  
      const response = await fetch(geocodeUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ latitude, longitude }),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        console.error("Response error:", {
          status: response.status,
          statusText: response.statusText,
          body: data,
        });
        throw new Error(`Failed to fetch geocoding data: ${response.status} ${JSON.stringify(data)}`);
      }
  
      if (data.results && data.results.length > 0) {
        const locality = data.results[0].address_components.find(
          (component) =>
            component.types.includes("locality") ||
            component.types.includes("sublocality") ||
            component.types.includes("postal_town")
        );
  
        const state = data.results[0].address_components.find(
          (component) => component.types.includes("administrative_area_level_1")
        );
  
        if (locality && state) {
          const locationString = `${locality.long_name}, ${state.short_name}`;
          setLocation(locationString);
          setErrors((prev) => ({ ...prev, location: false }));
        } else {
          const formattedAddress = data.results[0].formatted_address.split(",").slice(0, 2).join(",").trim();
          setLocation(formattedAddress);
          setErrors((prev) => ({ ...prev, location: false }));
        }
      } else {
        throw new Error("No results found in geocoding response");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      setErrors((prev) => ({
        ...prev,
        location: true,
        locationError: "Could not determine your location. Please enter it manually.",
      }));
    }
  };
  
  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          convertCoordsToLocation(latitude, longitude);
        },
        (error) => {
          console.error("Geolocation error:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div className="mainframe">
      <FormHeader />
      <Helmet>
        <title>Spiggl</title>
      </Helmet>
        <div className="search-group-display">
          <div className="search-group">
            <div className="select-group">
              <Select
                classNamePrefix="main-react-select"
                className="type-react-select"
                value={selectedContractorType}
                onChange={handleContractorTypeChange}
                options={contractorTypes}
                placeholder="Contractor Type"
                isClearable
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderRadius: '15px',
                    border: `3px solid ${errors.contractorType ? '#d3211b' : '#045096'}`,
                    padding: '0.5rem',
                    fontFamily: '"Inter-Regular", sans-serif',
                    fontSize: '1.5rem',
                    boxShadow: state.isFocused ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : null,
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    textAlign: 'left',
                  }),
                }}
              />
              <div className="location-input">
                <AsyncSelect
                  classNamePrefix="main-react-select"
                  className="location-react-select"
                  value={location ? { label: location, value: location } : null}
                  onChange={(selectedOption) => {
                    setLocation(selectedOption ? selectedOption.value : '');
                    setErrors((prev) => ({ ...prev, location: false }));
                  }}
                  loadOptions={loadOptions}
                  placeholder="Location"
                  isClearable
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderRadius: '15px',
                      border: `3px solid ${errors.location ? '#d3211b' : '#045096'}`,
                      padding: '0.5rem',
                      fontFamily: '"Inter-Regular", sans-serif',
                      fontSize: '1.5rem',
                      boxShadow: state.isFocused ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : null,
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      textAlign: 'left',
                    }),
                  }}
                />
                <img 
                  src="/icons/location.png" 
                  alt="Location Icon" 
                  className="location-icon" 
                  onClick={handleLocationClick} 
                />
              </div>
            </div>
            <button className="search-button" onClick={handleSearch}>
              Search
            </button>
          </div>
        </div>
      <Footer />
    </div>
  );
};

export default Mainframe;