import React, { useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import FormHeader from '../../../Headers/FormHeader/FormHeader';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from "../../../../auth/auth";
import { Helmet } from 'react-helmet';
import * as queries from '../../../../graphQL/queries';
import * as mutations from '../../../../graphQL/mutations';
import Modal from 'react-modal';
import { processPayment } from '../processPayment';
import PaymentLoader from '../PaymentLoader/PaymentLoader';
import PaymentMessageModal from '../PaymentMessageModal/PaymentMessageModal';
import './InvoiceZoom.css';

Modal.setAppElement('#root');

const InvoiceZoom = () => {
  const { invoiceID } = useParams();
  const [userData, setUserData] = useState({});
  const [invoice, setInvoice] = useState({});
  const [company, setCompany] = useState({});
  const { userID } = useAuth();
  const [selectedMethod, setSelectedMethod] = useState(null); 
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [paymentMessageModalOpen, setPaymentMessageModalOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState({ isSuccess: false, message: '' });
  const [loading, setLoading] = useState(false);
  const [reviewModalIsOpen, setReviewModalIsOpen] = useState(false);
  const [platformFeeAmount, setPlatformFeeAmount] = useState(0);
  const [stripeFee, setStripeFee] = useState(0);
  const [bankToken, setBankToken] = useState(null);
  const [stripeAmount, setStripeAmount] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const client = generateClient();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const { data: cardData } = await client.graphql({
          query: queries.paymentMethodsByUserID,
          variables: { userID: userID }
        });
        const paymentMethods = cardData.paymentMethodsByUserID.items;
        const activeMethod = paymentMethods.find(method => method.isActive);
        if (activeMethod) {
          setSelectedMethod(activeMethod);
        }
      } catch (error) {
      }
    };
    if (userID) {
      fetchPaymentMethods();
    }
  }, [userID, client]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await client.graphql({
          query: queries.getUser,
          variables: { id: userID }
        });

        if (data.getUser) {
          setUserData(data.getUser);
        } else {
        }
      } catch (error) {
      }
    };

    const fetchInvoiceData = async () => {
      try {
        const { data } = await client.graphql({
          query: queries.getInvoices,
          variables: { id: invoiceID }
        });
    
        if (data.getInvoices) {
          setInvoice({
            ...data.getInvoices,
            status: data.getInvoices.totalAmount === 0 ? 'Complete' : data.getInvoices.status,
          });
    
          const companyData = await client.graphql({
            query: queries.getCompany,
            variables: { id: data.getInvoices.companyID }
          });
    
          if (companyData.data.getCompany) {
            setCompany(companyData.data.getCompany);
            setBankToken(companyData.data.getCompany.stripeAccountId); 
          }
    
          const currentPayment = getCurrentPayment(data.getInvoices);
          if (currentPayment) {
            await calculateUserFees(currentPayment.amount);
          }
    
          await client.graphql({
            query: mutations.updateInvoices,
            variables: { input: { id: invoiceID, readStatus: true } }
          });
        }
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };
    

    fetchUserData();
    fetchInvoiceData();
  }, [invoiceID, userID, client]);

  const calculateUserFees = async (paymentAmountNum) => {
    try {
        const response = await fetch(process.env.REACT_APP_PAYMENT_API_ENDPOINT, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                invoiceAmount: paymentAmountNum,
                type: 'user'
            }),
        });

        const result = await response.json();

        if (response.ok) {
            setStripeFee(result.stripeFee);
            setTotalPayment(result.userTotal);
            setStripeAmount(result.stripeAmount);
            setPlatformFeeAmount(result.platformFeeAmount);
            return result; 
        } else {
            throw new Error(result.error || 'Failed to calculate fees');
        }
    } catch (error) {
        console.error('Error calculating fees:', error);
        throw error;
    }
  };

  const fetchNotificationSettings = async (id, type) => {
    try {
      if (type === 'user') {
        const userResult = await client.graphql({ query: queries.getUser, variables: { id } });
        return userResult?.data?.getUser || {};
      } else if (type === 'company') {
        const companyResult = await client.graphql({ query: queries.getCompany, variables: { id } });
        return companyResult?.data?.getCompany || {};
      }
    } catch (error) {
      console.error(`Error fetching ${type} notification settings:`, error);
      return null;
    }
  };  

  const invokePaymentEmailLambda = async (recipientEmail, recipientType, senderName, paymentDetails) => {
    const API_ENDPOINT = process.env.REACT_APP_EMAIL_PAYMENT_NOTIFICATION_API;
  
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ recipientEmail, recipientType, senderName, paymentDetails }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to send payment notification');
      }
  
      console.log('Payment notification sent successfully');
    } catch (error) {
    }
  };

  const handlePayment = async (amount) => {
    if (!selectedMethod) {
        setPaymentStatus({
            isSuccess: false,
            message: 'Please attach a valid payment method before proceeding.'
        });
        setPaymentMessageModalOpen(true);
        return;
    }

    const paymentAmountNum = parseFloat(amount || currentPayment?.amount);

    if (isNaN(paymentAmountNum) || paymentAmountNum <= 0 || paymentAmountNum > currentPayment?.amount) {
        setPaymentStatus({
            isSuccess: false,
            message: `Please enter a valid payment amount. The amount cannot exceed $${currentPayment?.amount}`
        });
        setPaymentMessageModalOpen(true);
        return;
    }

    setLoading(true);

    try {
        const feeResponse = await calculateUserFees(paymentAmountNum);
        if (!feeResponse) {
            throw new Error('Failed to calculate fees');
        }

        const updatedPayments = [...invoice.payments];
        const updatedDueDates = [...invoice.dueDates];
        const updatedPaymentDates = [...invoice.paymentDate || []];
        const updatedLatePayments = [...invoice.latePayments || []];
        const updatedFeeAmountIncluded = [...(invoice.feeAmountIncluded || [])];

        const dueDate = new Date(updatedDueDates[0]);
        const paymentDate = new Date();
        const timeDifferenceInMs = paymentDate - dueDate;
        const dayDifference = Math.floor(timeDifferenceInMs / (1000 * 60 * 60 * 24));

        updatedLatePayments.push(dayDifference);
        updatedPayments[0] -= paymentAmountNum;
        updatedPaymentDates.push(paymentDate.toISOString());
        updatedFeeAmountIncluded.push(totalPayment);

        if (updatedPayments[0] === 0) {
            updatedPayments.shift();
            updatedDueDates.shift();
        }

        const updatedTotalAmount = invoice.totalAmount - paymentAmountNum;
        const input = {
            id: invoice.id,
            totalAmount: updatedTotalAmount,
            payments: updatedPayments,
            dueDates: updatedDueDates,
            paymentDate: updatedPaymentDates,
            latePayments: updatedLatePayments,
            feeAmountIncluded: updatedFeeAmountIncluded,
            status: updatedTotalAmount === 0 ? 'Complete' : invoice.status,
        };

        if (!bankToken) {
            throw new Error("No bank account available for the company. Please consult the sender of the invoice.");
        }

        const result = await processPayment({
            payment_method: selectedMethod.stripeCardToken,
            amount: stripeAmount, 
            contractorBankToken: bankToken,
            platformFeeAmount,
        });

        if (result.success) {
            await client.graphql({
                query: mutations.updateInvoices,
                variables: { input }
            });

            setInvoice({
                ...invoice,
                totalAmount: updatedTotalAmount,
                payments: updatedPayments,
                dueDates: updatedDueDates,
                paymentDate: updatedPaymentDates,
                latePayments: updatedLatePayments,
                feeAmountIncluded: updatedFeeAmountIncluded,
                status: updatedTotalAmount === 0 ? 'Complete' : invoice.status,
            });

            const paymentDetails = {
                amount: totalPayment,
                contractorAmount: feeResponse.contractorShare,
                paymentNumber: invoice.payments.length - updatedPayments.length,
                totalPayments: invoice.payments.length,
                paymentDate: paymentDate.toISOString(),
                dueDate: invoice.dueDates[0] || null,
                platformFee: platformFeeAmount, 
                stripeFee: stripeFee,      
            };

            const userNotificationSettings = await fetchNotificationSettings(userID, 'user');
            const companyNotificationSettings = await fetchNotificationSettings(company.id, 'company');

            if (companyNotificationSettings?.paymentNotifications) {
                invokePaymentEmailLambda(company.email, 'company', userData.name || userData.user, paymentDetails);
            }

            if (userNotificationSettings?.paymentNotifications) {
                invokePaymentEmailLambda(userData.email, 'user', company.companyName || company.companyUser, paymentDetails);
            }

            setPaymentStatus({
                isSuccess: true,
                message: 'Payment successful'
            });
        } else {
            setPaymentStatus({
                isSuccess: false,
                message: result.message || 'Payment processing failed.'
            });
        }
    } catch (error) {
        setPaymentStatus({
            isSuccess: false,
            message: error.message || 'An error occurred during payment.'
        });
    } finally {
        setLoading(false);
        setPaymentMessageModalOpen(true);
    }
  };

  const handlePaymentMessageModalClose = () => {
      setPaymentMessageModalOpen(false);
      if (paymentStatus.isSuccess && invoice.totalAmount === 0) {
          setReviewModalIsOpen(true);
      }
  };

  const formatAmountWithCommas = (amount) => {
    if (isNaN(amount)) return '0.00';
    return Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };
  
  const getCurrentPayment = () => {
    if (invoice.oneTimePayment) {
      const adjustedDueDate = new Date(invoice.dueDates[0]);
      adjustedDueDate.setDate(adjustedDueDate.getDate() + 1); 
      return {
        amount: invoice.totalAmount,
        dueDate: formatDate(adjustedDueDate)
      };
    }
  
    if (invoice.payments && Array.isArray(invoice.payments)) {
      const nextPaymentIndex = invoice.payments.findIndex((payment, index) => new Date(invoice.dueDates[index]) > new Date());
      if (nextPaymentIndex !== -1) {
        return {
          amount: invoice.payments[nextPaymentIndex],
          dueDate: formatDate(invoice.dueDates[nextPaymentIndex])
        };
      }
    }
  
    return null;
  };  

  const getNextPayments = () => {
    if (invoice.oneTimePayment || !invoice.payments || !Array.isArray(invoice.payments)) {
      return [];
    }

    const nextPaymentIndex = invoice.payments.findIndex((payment, index) => new Date(invoice.dueDates[index]) > new Date());
    if (nextPaymentIndex !== -1) {
      return invoice.payments.slice(nextPaymentIndex + 1).map((payment, index) => ({
        amount: payment,
        dueDate: formatDate(invoice.dueDates[nextPaymentIndex + 1 + index])
      }));
    }

    return [];
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric'
    });
  };
 
  const handlePostReviewClick = () => {
    navigate(`/user/${userData.user}/post-review`, {
      state: {
        companyUser: company.companyUser,
      },
    });
  };
  
  const currentPayment = getCurrentPayment();
  const nextPayments = getNextPayments();
  const paymentAmountNum = currentPayment ? currentPayment.amount : 0;

  return (
    <div className="invoice-zoom-full-page">
      <FormHeader />
      <Helmet>
        <title>{`Invoice from ${company.companyName || company.companyUser}`}</title>
      </Helmet>
      {loading && (
        <div className="payment-loader-overlay">
          <PaymentLoader />
        </div>
      )}
      <div className="invoice-zoom-container">
        <div className="invoice-zoom-header">
          <div className="invoice-zoom-company-details">
            <div className="invoice-zoom-company-profile">
              {company.profilepicturekey && (
                <img src={`https://media.spiggl.com/public/${company.profilepicturekey}`} alt={company.companyName} />
              )}
              <div className="invoice-zoom-company-name">
                {company.companyName || company.companyUser ? (
                  <a 
                    href={`/contractor/${company.companyUser}`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="company-link"
                  >
                    {company.companyName || company.companyUser}
                  </a>
                ) : (
                  company.companyName || company.companyUser
                )}
              </div>
            </div>
          </div>
          <div className="invoice-zoom-total-amount-wrapper">
            <div className="invoice-zoom-total-amount">
              Total: $
              {invoice.amount ? formatAmountWithCommas(invoice.amount) : '0.00'} 
            </div>
            <div className="invoice-zoom-total-amount">
              Remaining: $
              {invoice.totalAmount ? formatAmountWithCommas(invoice.totalAmount) : '0.00'} 
            </div>
          </div>
        </div>
        <div className="invoice-zoom-sections">
          <div className="invoice-zoom-left">
            <div className="invoice-zoom-content">
              <div className="invoice-zoom-content-header">Current Payment</div>
              {paymentAmountNum === 0 ? (
                <div className="no-more-payments">No more payments due</div>
              ) : (
                <>
                  {currentPayment?.dueDate && <div className="invoice-zoom-due-date">Due: {currentPayment.dueDate}</div>}
                  <div className="payment-details">
                    <div><span>Payment Amount:</span><span>${formatAmountWithCommas(paymentAmountNum)}</span></div>
                    <div><span>Service:</span><span>${formatAmountWithCommas(stripeFee)}</span></div>
                    <div><span>Total Payment:</span><span>${formatAmountWithCommas(totalPayment)}</span></div>
                  </div>
                </>
              )}
              {selectedMethod && (
                <div className="active-payment-method-card">
                  <div className="card-brand">
                    {selectedMethod.brand} ending in {selectedMethod.last4}
                  </div>
                  <div className="card-expiry">
                    Exp: {selectedMethod.expMonth}/{selectedMethod.expYear}
                  </div>
                </div>
              )}
              {invoice.totalAmount > 0 && (
                <>
                  <div className="invoice-zoom-pay-button-container">
                    <button className="invoice-zoom-pay-button" onClick={() => handlePayment(paymentAmountNum)}>Pay Now</button>
                  </div>
                </>
              )}
            </div>
            <div className="invoice-zoom-payment-schedule"> 
              {invoice.status !== 'Complete' && (
                <div className="invoice-zoom-payment-schedule-title">Payment Schedule</div>
              )}
              {nextPayments.length > 0 ? (
                <table className="invoice-zoom-payment-schedule-grid">
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Due</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nextPayments.map((payment, index) => (
                      <tr key={index}>
                        <td>${payment.amount ? formatAmountWithCommas(payment.amount) : '0.00'}</td>
                        <td>{payment.dueDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                invoice.paymentDate && invoice.paymentDate.length > 0 ? (
                  <div className="completed-payments-section">
                    <div className="completed-payments-title">Completed Payments</div>
                    <table className="invoice-zoom-payment-schedule-grid">
                      <thead>
                        <tr>
                          <th>Amount</th>
                          <th>Payment Date</th>
                          <th>Due Date</th>
                          <th>On time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoice.paymentDate.map((paymentDate, index) => {
                          const paymentAmount = invoice.feeAmountIncluded?.[index] || 0;
                          const dueDate = invoice.dueDateLog && invoice.dueDateLog[index] ? invoice.dueDateLog[index] : 'N/A';
                          const formattedPaymentDate = new Date(paymentDate).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true,
                          });

                          const isLate = new Date(paymentDate) > new Date(dueDate).setHours(23, 59, 59, 999);
                          const statusIcon = isLate ? '/icons/xmark.png' : '/icons/check.png';

                          return (
                            <tr key={index}>
                              <td>${formatAmountWithCommas(paymentAmount)}</td>
                              <td>{formattedPaymentDate}</td>
                              <td>{dueDate !== 'N/A' ? formatDate(new Date(dueDate).setDate(new Date(dueDate).getDate() + 1)) : 'N/A'}</td>
                              <td>
                                <img src={statusIcon} alt={isLate ? 'Late' : 'On Time'} width={20} height={20} />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="no-schedule">
                    <p>No Schedule Available</p>
                    <img src="/icons/schedule.png" alt="No Schedule" />
                  </div>
                )
              )}
            </div>
          </div>
          <div className="invoice-zoom-right">
            <div className="invoice-zoom-invoice-file">
              {invoice.invoiceAttachmentKey ? (
                <embed src={`https://media.spiggl.com/public/${invoice.invoiceAttachmentKey}`} type="application/pdf" width="100%" height="400px" />
              ) : (
                <div className="no-invoice">
                  <p>No Paper Invoice Available</p>
                </div>
              )}
              {invoice.invoiceAttachmentKey && (
                <div className="invoice-zoom-buttons">
                  <button className="expand-button" onClick={() => setModalIsOpen(true)}>Expand</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="invoice-modal"
        overlayClassName="invoice-modal-overlay"
      >
        <button className="invoice-close-modal-button" onClick={() => setModalIsOpen(false)}>Close</button>
        <div className="invoice-modal-content">
          {invoice.invoiceAttachmentKey ? (
            <embed src={`https://media.spiggl.com/public/${invoice.invoiceAttachmentKey}`} type="application/pdf" width="100%" height="600px" />
          ) : (
            <p>No Invoice Available</p>
          )}
        </div>
      </Modal>
      <PaymentMessageModal 
          isOpen={paymentMessageModalOpen}
          onClose={handlePaymentMessageModalClose}
          isSuccess={paymentStatus.isSuccess}
          message={paymentStatus.message}
          amount={formatAmountWithCommas(totalPayment)}
          contractorName={company.companyName || company.companyUser}
      />
      <Modal
        isOpen={reviewModalIsOpen}
        onRequestClose={() => setReviewModalIsOpen(false)}
        className="review-modal"
        overlayClassName="review-modal-overlay"
      >
        <div className="review-modal-content">
          <h2>How did {company.companyName || company.companyUser} do?</h2>
          <h2>Give em a review!</h2>
          <div className="review-modal-buttons">
            <button className="write-review-button" onClick={handlePostReviewClick}>Write Review</button>
            <button className="no-thanks-button" onClick={() => setReviewModalIsOpen(false)}>No Thanks</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InvoiceZoom;
