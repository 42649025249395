import React, { useState, useEffect } from 'react';
import FormHeader from '../../../Headers/FormHeader/FormHeader';
import { generateClient } from 'aws-amplify/api';
import { uploadData } from 'aws-amplify/storage';
import { useAuth } from '../../../../auth/auth';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Select from 'react-select';
import * as mutations from '../../../../graphQL/mutations';
import * as queries from '../../../../graphQL/queries';
import SearchUser from '../../../Syn/SearchUser/SearchUser';
import './SendInvoice.css';

const SendInvoice = () => {
  const { userID: companyID } = useAuth();
  const [selectedUser, setSelectedUser] = useState(null);
  const [totalAmount, setTotalAmount] = useState('');
  const [oneTimePayment, setOneTimePayment] = useState(true);
  const [frequency, setFrequency] = useState(null);
  const [numberOfPayments, setNumberOfPayments] = useState('');
  const [evenPayments, setEvenPayments] = useState(true);
  const [customPayments, setCustomPayments] = useState([]);
  const [invoiceAttachment, setInvoiceAttachment] = useState(null);
  const [companyData, setCompanyData] = useState({});
  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const navigate = useNavigate();
  const client = generateClient();

  const calculateDueDateOneWeekLater = () => {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    return date.toISOString().split('T')[0];
  };

  const [dueDate, setDueDate] = useState(calculateDueDateOneWeekLater());

  const frequencyOptions = [
    { label: "Weekly", value: "Weekly" },
    { label: "Bi-Weekly", value: "Bi-Weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Bi-Monthly", value: "Bi-Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Yearly", value: "Yearly" },
  ];

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await client.graphql({
          query: queries.getCompany,
          variables: { id: companyID },
        });

        if (response.data.getCompany) {
          setCompanyData(response.data.getCompany);
        } else {
          console.error('Company data not found.');
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    fetchCompanyData();
  }, [companyID, client]);

  const handleAttachmentChange = (event) => {
    const file = event.target.files[0];
    setInvoiceAttachment(file);
  };

  const generateUniqueKey = (file) => {
    return `invoices/${Date.now()}_${Math.random().toString(36).substr(2, 9)}_${file.name}`;
  };

  const uploadToS3 = async (file) => {
    const key = generateUniqueKey(file);
    try {
      const result = await uploadData({
        key,
        data: file,
        options: {
          contentType: file.type,
          level: 'public',
        },
      });
      console.log('File uploaded successfully:', key);
      return key;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const calculateDueDates = (numberOfPayments, frequency) => {
    return Array.from({ length: numberOfPayments }, (_, index) => calculateDueDateByFrequency(index + 1, frequency));
  };

  const calculateDueDateByFrequency = (paymentNumber, frequency) => {
    const date = new Date();
    switch (frequency) {
      case 'Weekly':
        date.setDate(date.getDate() + (7 * paymentNumber));
        break;
      case 'Bi-Weekly':
        date.setDate(date.getDate() + (14 * paymentNumber));
        break;
      case 'Monthly':
        date.setMonth(date.getMonth() + paymentNumber);
        break;
      case 'Bi-Monthly':
        date.setMonth(date.getMonth() + (2 * paymentNumber));
        break;
      case 'Quarterly':
        date.setMonth(date.getMonth() + (3 * paymentNumber));
        break;
      case 'Yearly':
        date.setFullYear(date.getFullYear() + paymentNumber);
        break;
      default:
        break;
    }
    return date.toISOString().split('T')[0];
  };

  const invokeInvoiceEmailLambda = async (recipientEmail, companyName, invoiceDetails, invoiceUrl) => {
    const API_ENDPOINT = process.env.REACT_APP_EMAIL_INVOICE_NOTIFICATION_API;
  
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recipientEmail,
          companyName,
          invoiceDetails,
          invoiceUrl,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to send invoice notification');
      }
  
      console.log('Invoice notification sent successfully');
    } catch (error) {
      console.error('Error sending invoice notification:', error);
    }
  };  

  const handleCreateInvoice = async () => {
    const today = new Date().toISOString().split('T')[0];
  
    if (new Date(dueDate) <= new Date(today)) {
      setError('Due date must be later than today.');
      return;
    }
  
    if (
      !selectedUser || 
      !totalAmount || 
      parseFloat(totalAmount) < 1 || 
      (!oneTimePayment && (!frequency || !numberOfPayments)) ||
      (!oneTimePayment && !evenPayments && customPayments.length !== parseInt(numberOfPayments, 10))
    ) {
      if (parseFloat(totalAmount) < 1) {
        setError('Total amount must be at least $1.');
      } else {
        setError('Please fill in all required fields');
      }
      return;
    }
  
    try {
      const response = await client.graphql({
        query: queries.bankMethodsByCompanyID,
        variables: { companyID },
      });
  
      const bankMethods = response.data.bankMethodsByCompanyID.items;
  
      if (bankMethods.length === 0) {
        setError('No available bank account for receiving payments. Please set up a bank method before sending the invoice.');
        return;
      }
    } catch (error) {
      console.error('Error fetching bank methods:', error);
      setError('Unable to verify bank methods.');
      return;
    }
  
    let invoiceAttachmentKey = null;
    if (invoiceAttachment) {
      invoiceAttachmentKey = await uploadToS3(invoiceAttachment);
    }
  
    let payments;
    let dueDates;
    if (oneTimePayment) {
      payments = [parseFloat(totalAmount)];
      dueDates = [dueDate];
    } else {
      const numPayments = parseInt(numberOfPayments, 10);
      if (evenPayments) {
        const eachPayment = parseFloat(totalAmount) / numPayments;
        payments = Array.from({ length: numPayments }, () => eachPayment);
      } else {
        if (customPayments.reduce((acc, val) => acc + parseFloat(val), 0) !== parseFloat(totalAmount)) {
          setError('Custom payments do not sum up to the total amount.');
          return;
        }
        payments = customPayments.map(amount => parseFloat(amount));
      }
      dueDates = calculateDueDates(numPayments, frequency.value);
    }
  
    const transactionFees = numberOfPayments ? numberOfPayments * 0.15 : 0.15;
    const adjustedTotal = parseFloat(totalAmount) * 1.03;
    const grandAmount = transactionFees + adjustedTotal;
  
    const input = {
      userID: selectedUser.id,
      companyID,
      totalAmount: parseFloat(totalAmount),
      dueDates,
      amount: totalAmount,
      oneTimePayment,
      frequency: oneTimePayment ? null : frequency.value,
      status: 'Pending',
      numberOfPayments: oneTimePayment ? 1 : parseInt(numberOfPayments, 10),
      invoiceAttachmentKey,
      payments,
      resendPayments: payments,
      resent: false,
      readStatus: false,
      dueDateLog: dueDates,
      grandAmount,
    };
  
    try {
      const response = await client.graphql({
        query: mutations.createInvoices,
        variables: { input },
      });
      setError('');
      console.log('Invoice created successfully');
  
      const userResponse = await client.graphql({
        query: queries.getUser,
        variables: { id: selectedUser.id },
      });
      const user = userResponse.data.getUser;
  
      if (user.invoiceNotifications) {
        const invoiceUrl = `https://spiggl.com/user/${selectedUser.user}/invoice-dash/invoice/${response.data.createInvoices.id}`;
        
        const invoiceDetails = {
          totalAmount: formatAmount(totalAmount),
          numberOfPayments: oneTimePayment ? 1 : parseInt(numberOfPayments, 10),
          payments: payments.map((amount, index) => ({
            amount: formatAmount(amount),
            due: dueDates[index],
          })),
        };
  
        const companyName = companyData.companyName || companyData.companyUser;
  
        invokeInvoiceEmailLambda(selectedUser.email, companyName, invoiceDetails, invoiceUrl);
      } else {
        console.log(`Invoice notifications are disabled for user: ${user.email}`);
      }
  
      navigate(-1);
    } catch (err) {
      console.error('Error creating invoice:', err);
      setError('Error creating invoice');
    }
  };
   

  const formatAmount = (amount) => {
    const value = parseFloat(amount);
    return isNaN(value) ? '' : value.toFixed(2);
  };

  const handleTotalAmountChange = (e) => {
    const value = e.target.value;
    const validNumberRegex = /^\d*\.?\d{0,2}$/;

    if (validNumberRegex.test(value)) {
      setTotalAmount(value);
    }
  };

  const handleTotalAmountBlur = () => {
    setTotalAmount(formatAmount(totalAmount));
  };

  const handleCustomPaymentChange = (index, value) => {
    const validNumberRegex = /^\d*\.?\d{0,2}$/;

    if (validNumberRegex.test(value)) {
      const newPayments = [...customPayments];
      newPayments[index] = value;
      setCustomPayments(newPayments);
    }
  };

  const handleUsernameChange = (result) => {
    setUsername(result.user); 
    setSelectedUser(result); 
  };

  return (
    <div className="send-invoice-container">
      <FormHeader />
      <Helmet>
        <title>Send Invoice</title>
      </Helmet>
      <div className="send-invoice">
        <div className="create-invoice-form">
          <div className="send-invoice-header">Send Invoice</div>
          <div className="form-field">
            <SearchUser
              userId={companyID}
              onSelectResult={handleUsernameChange}
              className="search-function-bar"
            />
          </div>
          <div className="form-field">
            <input
              type="text"
              placeholder="Total Amount"
              value={totalAmount}
              onChange={handleTotalAmountChange}
              onBlur={handleTotalAmountBlur}
              required
            />
          </div>
          <label className="switch-label">
            One Time Payment?
            <div
              className={`switch ${!oneTimePayment ? 'on' : 'off'}`}
              onClick={() => setOneTimePayment(!oneTimePayment)}
            >
              <div className="toggle" />
            </div>
          </label>
          {!oneTimePayment && (
            <>
              <div className="form-field">
                <Select
                  classNamePrefix="send-react-select"
                  className="send-invoice-react-select"
                  value={frequency}
                  onChange={(option) => {
                    if (option && option.value !== frequency?.value) {
                      setFrequency(option);
                    }
                  }}
                  options={frequencyOptions}
                  placeholder="Frequency"
                  isClearable
                  required={!oneTimePayment}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                      padding: '7px 5px',
                      textAlign: 'left',
                      fontFamily: 'Poppins", sans-serif',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      textAlign: 'left',
                    }),
                  }}
                />
              </div>
              <div className="form-field">
                <input
                  type="number"
                  placeholder="Number of Payments"
                  value={numberOfPayments}
                  onChange={(e) => setNumberOfPayments(e.target.value)}
                  min="1"
                  max="10"  
                  required={!oneTimePayment}
                  step="1"
                  inputMode="numeric"
                  pattern="\d*"
                />
              </div>
              <label className="switch-label">
                Even Payments?
                <div
                  className={`switch ${!evenPayments ? 'on' : 'off'}`}
                  onClick={() => setEvenPayments(!evenPayments)}
                >
                  <div className="toggle" />
                </div>
              </label>
              <div className='invoice-amount-dropdown'>
                {!evenPayments && (
                  <>
                    {Array.from({ length: numberOfPayments }, (_, index) => (
                      <div className="form-field">
                        <input
                          key={index}
                          type="text"
                          placeholder={`Payment ${index + 1} Amount`}
                          value={customPayments[index] || ''}
                          onChange={(e) => handleCustomPaymentChange(index, e.target.value)}
                          required={!oneTimePayment}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            </>
          )}
          {oneTimePayment && (
            <div className="date-picker-container">
              <label>Due:</label>
              <input
                type="date"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
                required
              />
            </div>
          )}
          <label className="file-input-label">
            <input
              type="file"
              className="file-input"
              onChange={handleAttachmentChange}
            />
            Choose File
          </label>
          {invoiceAttachment && <div className="file-name">{invoiceAttachment.name}</div>}
          {error && <div className="error">{error}</div>}
          <button onClick={handleCreateInvoice}>Send Invoice</button>
        </div>
      </div>
    </div>
  );
};

export default SendInvoice;
