import React, { useEffect } from 'react';
import './AboutUs.css';
import FormHeader from '../../Headers/FormHeader/FormHeader';
import Footer from '../../Footer/Footer';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

function AboutUs() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigateToContact = () => {
    navigate('/contact');
  };

  return (
    <div className="AboutUs">
      <FormHeader />
      <Helmet>
        <title>About Us</title>
      </Helmet>
      <div className="about-us-header">
        <div className="about-us-title-banner">
          <div className="about-us-title">
            About Us
          </div>
        </div>
        <img src='/beach.jpg' alt="About Us" className="about-us-banner" />
        <div className="about-us-subtitle">
          Championing transparency and ease
        </div>
      </div>
      <div className="about-us-transition">
        <div className="transition-effect"></div>
      </div>
      <div className="about-us-content">
        <div className="about-us-section">
          <div className="about-image-container">
            <img src='/me.jpg' alt="Person 1" className="about-us-image" />
            <div className="about-social-icons">
              <a href="https://www.instagram.com/andrew.daly6/" target="_blank" rel="noopener noreferrer" className="about-social-icon instagram" aria-label="Instagram">
                <img src="/icons/instagram.png" alt="Instagram" />
              </a>
              <a href="https://www.linkedin.com/in/andrew-daly-293524229/" target="_blank" rel="noopener noreferrer" className="about-social-icon linkedin" aria-label="LinkedIn">
                <img src="/icons/linkedin.png" alt="LinkedIn" />
              </a>
              <a href="https://open.spotify.com/user/adaly2001" target="_blank" rel="noopener noreferrer" className="about-social-icon spotify" aria-label="Spotify">
                <img src="/icons/spotify.png" alt="Spotify" />
              </a>
            </div>
          </div>
          <div className="about-us-text">
            <h2 className="card-name">Andrew Daly</h2>
            <h3 className="card-title">President, Co-Founder</h3>
            <p className="card-description">Write a short description about Person 1 here. Placeholder text...</p>
            <div className="card-details">
              <div className="card-column">
                <h4>Pros</h4>
                <ul>
                  <li>Pro 1</li>
                  <li>Pro 2</li>
                </ul>
              </div>
              <div className="card-column">
                <h4>Cons</h4>
                <ul>
                  <li>Con 1</li>
                  <li>Con 2</li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>
        <div className="about-us-section">
          <div className="about-us-text">
            <h2 className="card-name">AJ Simcox</h2>
            <h3 className="card-title">Co-Founder</h3>
            <p className="card-description">Write a short description about Person 2 here. Placeholder text...</p>
            <div className="card-details">
              <div className="card-column">
                <h4>Pros</h4>
                <ul>
                  <li>Pro 1</li>
                  <li>Pro 2</li>
                </ul>
              </div>
              <div className="card-column">
                <h4>Cons</h4>
                <ul>
                  <li>Con 1</li>
                  <li>Con 2</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="about-image-container">
            <img src='/aj.jpg' alt="Person 2" className="about-us-image reverse" />
            <div className="about-social-icons">
              <a href="https://open.spotify.com/user/31egc3w2ebkfckvsoqpyxzmrxgzi" target="_blank" rel="noopener noreferrer" className="about-social-icon spotify" aria-label="Spotify">
                <img src="/icons/spotify.png" alt="Spotify" />
              </a>
              <a href="https://www.instagram.com/simcoxaj/" target="_blank" rel="noopener noreferrer" className="about-social-icon instagram" aria-label="Instagram">
                <img src="/icons/instagram.png" alt="Instagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="about-us-transition">
        <div className="transition-effect"></div>
      </div>
      <div className="our-story-section">
        <h2 className="our-story-title">Story time</h2>
        <p className="our-story-paragraph">Placeholder text for the story section. Write a brief description about the company's story, mission, and values. This is where you can share the journey and milestones of your company.</p>
      </div>
      <div className="about-us-transition">
        <div className="transition-effect"></div>
      </div>
      <div className="join-us-section">
        <h2 className="join-us-title">Join us in our journey</h2>
        <p className="join-us-paragraph">
          Visit the Contact Us page to express your interest in joining our team.
        </p>
        <button className="about-button" onClick={navigateToContact}>
          Contact
            <path
              clipRule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
              fillRule="evenodd"
            />
        </button>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
